import React, { useState, useEffect } from 'react'
import {
  Container,
  Navbar,
  Button,
} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import Icon from '../shared/Icon'
import Modal from '../shared/Modal'
import { useGetEventQuery } from '../../api/apiSlice'
import { setIsSignedIn, setEventId, setAccessCode } from '../../api/authentication'

import './Header.scss'

const Header = function () {
  const dispatch = useDispatch()
  const accessCode = localStorage.getItem('accessCode')
  const [showLogoutModal, setShowLogoutModal] = useState(false)
  const modeConfig = useSelector((state) => state.modeConfig.data)

  const {
    data: event,
    isSuccess,
    isError,
    refetch,
  } = useGetEventQuery(accessCode)

  const logoutUser = () => {
    localStorage.removeItem('accessCode')
    dispatch(setIsSignedIn(false))
  }

  useEffect(() => {
    if (isSuccess) {
      dispatch(setAccessCode(accessCode))
      dispatch(setEventId(event.events[0].id))
    }
    if (isError) {
      localStorage.removeItem('accessCode')
      dispatch(setAccessCode(''))
      dispatch(setIsSignedIn(false))
    }
  }, [isSuccess, isError])

  /*
    The accessCode is stored in the localStorage.
    After reloading the app, if the access code is still valid,
    user will be signed-in automatically.

    But the app always need to check if the access code
    is still valid on component mount.
    If not, the user will be redirected to the login page.

  */
  useEffect(() => {
    refetch()
  }, [])

  return (
    <header className={`attendease-header ${modeConfig?.mode}`}>
      <Navbar expand="lg">
        <Container>
          <Link to="/">
            <img src={modeConfig?.images.checkin_app_logo} alt={`${modeConfig?.text.platform_name} On-site Check-in`} />
          </Link>
          <Button
            className="reset-styles attendease-header-logout"
            onClick={() => setShowLogoutModal(true)}
          >
            <Icon
              beforeText
              category="regular"
              type="power-off"
            />
            Logout
          </Button>
        </Container>
      </Navbar>

      {isSuccess && (
        <div className="attendease-event-name">
          <span>{event.events[0].name}</span>
        </div>
      )}

      <Modal
        show={showLogoutModal}
        title="Log Out"
        className="attendease-logout-modal"
        closeModal={() => setShowLogoutModal(false)}
        closeButtonText="Close"
        showCloseOnHeader
      >
        <div className="text-center">
          <p>
            You will need the access code to sign back into the event.
            Are you sure you want to log out?
          </p>

          <Button
            variant="danger"
            onClick={logoutUser}
          >
            Log Out
          </Button>
        </div>
      </Modal>
    </header>
  )
}

export default Header
