import React from 'react'
import PropTypes from 'prop-types'
import Paginator from '../shared/Paginator'
import TimeSlot from './TimeSlot'

import './AttendeeTimeSlots.scss'

const AttendeeTimeSlots = function ({
  timeSlots = [],
  pagination = {},
  page,
  setPage,
  attendeeId,
}) {
  return (
    <>
      <div className="attendease-attendee-time-slot-listing">
        {timeSlots.map((timeSlot) => {
          const attendeeCheckin = `${timeSlot.checkinDate} ${timeSlot.checkinTime}`
          return (
            <TimeSlot
              attendeeId={attendeeId}
              timeSlot={timeSlot}
              attendeeCheckin={attendeeCheckin}
            />
          )
        })}
      </div>

      {(pagination.pageCount > 1) && (
        <div className="mb-2">
          <Paginator page={page} pageCount={pagination.pageCount} onChange={setPage} />
        </div>
      )}
    </>
  )
}

AttendeeTimeSlots.propTypes = {
  timeSlots: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  pagination: PropTypes.shape({}).isRequired,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  attendeeId: PropTypes.string.isRequired,
}

export default AttendeeTimeSlots
