import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isSignedIn: false,
  eventId: '',
  accessCode: '',
}

export const authenticationSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    setIsSignedIn(state, action) {
      const currentState = state
      currentState.isSignedIn = action.payload
    },
    setEventId(state, action) {
      const currentState = state
      currentState.eventId = action.payload
    },
    setAccessCode(state, action) {
      const currentState = state
      currentState.accessCode = action.payload
    },
  },
})

export const { setIsSignedIn, setEventId, setAccessCode } = authenticationSlice.actions

export default authenticationSlice.reducer
