import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'react-bootstrap'

import './ReloadButton.scss'
import Icon from '../Icon'

/**
 * It renders a `<button>` with a reload icon.
 * Use this component to refetch data from some API endpoint.
*/
const ReloadButton = function ({
  onClick,
}) {
  return (
    <Button
      variant="default"
      className="attendease-reload-button"
      onClick={onClick}
    >
      <Icon
        category="regular"
        type="rotate"
      />
    </Button>
  )
}

ReloadButton.propTypes = {
  /**
    Callback is triggered on user click.
  */
  onClick: PropTypes.func.isRequired,
}

export default ReloadButton
