import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button } from 'react-bootstrap'
import Icon from '../Icon'
import Spinner from '../Spinner'

import './CheckInButton.scss'

const CheckInButton = function ({
  label = 'Check-in',
  checkAttendeeIn,
  checkInDate,
  isCheckInSuccess,
  isLoading,
  className,
}) {
  const [isConfirming, setIsConfirming] = useState(false)

  if (isConfirming) {
    setTimeout(() => {
      setIsConfirming(false)
    }, 4000)
  }

  const handleCheckin = () => {
    setIsConfirming(false)
    checkAttendeeIn()
  }

  if (isLoading) {
    return (
      <Button
        className={`attendease-checkin-button ${className}`}
        variant="success"
        onClick={null}
      >
        <Spinner />
      </Button>
    )
  }

  if (checkAttendeeIn && !checkInDate) {
    return (
      <Button
        className={`attendease-checkin-button ${className}`}
        variant={isConfirming ? 'info' : 'success'}
        onClick={isConfirming ? handleCheckin : () => setIsConfirming(true)}
      >
        {(!!checkAttendeeIn && !isConfirming) && (
          <>
            <Icon
              category="solid"
              type="user-plus"
            />
            {label}
          </>
        )}

        {isConfirming && (
          <>
            Confirm?
            <Icon
              category="solid"
              type="check"
            />
          </>
        )}
      </Button>
    )
  }

  if (checkInDate || isCheckInSuccess) {
    return (
      <Button
        className={`attendease-checkin-button ${className}`}
        variant="secondary"
        onClick={null}
      >
        <Icon
          category="solid"
          type="user-check"
        />

        {checkInDate}
      </Button>
    )
  }

  return null
}

CheckInButton.defaultProps = {
  checkAttendeeIn: null,
  checkInDate: '',
  isCheckInSuccess: false,
  isLoading: false,
  className: '',
  label: 'Check-in',
}

CheckInButton.propTypes = {
  /**
    Function to check-in an attendee into an event or session.
  */
  checkAttendeeIn: PropTypes.func,
  /**
    Date and time that the attendee was last checked in.
  */
  checkInDate: PropTypes.string,
  /**
   If true, it will show "Checked in a few seconds ago" text.
  */
  isCheckInSuccess: PropTypes.bool,
  /**
   If true, it will show a <Spinner /> component.
  */
  isLoading: PropTypes.bool,
  /**
   Add a CSS class to the `attendease-checkin-button` element.
  */
  className: PropTypes.string,
  /**
   The button's text. Default value is "Check-in".
  */
  label: PropTypes.string,
}

export default CheckInButton
