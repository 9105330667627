/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react'
import PropTypes from 'prop-types'
import Icon from '../Icon'

import './SocialProfiles.scss'

const SOCIAL = [
  {
    key: 'linkedin',
    icon: 'linkedin',
  },
  {
    key: 'facebook',
    icon: 'facebook-square',
  },
  {
    key: 'instagram',
  },
  {
    key: 'twitter',
  },
]

/**
 * Renders a list of social icons (Facebook, Instagram, LinkedIn, and Twitter).
*/
const SocialProfiles = function (props) {
  return (
    <div className="attendease-social-profiles">
      {SOCIAL.map((s) => {
        if (props[s.key]) {
          return (
            <a
              key={s.key}
              className={`social-icon ${s.key}`}
              href={`//${s.url ? s.url : `${s.key}.com`}/${props[s.key]}`}
              rel="noopener noreferrer"
              target="_blank"
            >
              <Icon
                category="brand"
                type={s.icon ? s.icon : s.key}
              />
            </a>
          )
        }
        return null
      })}
    </div>
  )
}

SocialProfiles.defaultProps = {
  facebook: '',
  instagram: '',
  linkedin: '',
  twitter: '',
}

SocialProfiles.propTypes = {
  facebook: PropTypes.string,
  instagram: PropTypes.string,
  linkedin: PropTypes.string,
  twitter: PropTypes.string,
}

export default SocialProfiles
