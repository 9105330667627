import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import Icon from '../shared/Icon'
import { getRoomCapacity } from '../../utils/tools'
import ScanSessionQrCode from '../ScanSessionQrCode'

import './SessionTimeSlot.scss'

const SessionTimeSlot = function ({
  id,
  name,
  venue,
  room,
  date,
  startTime,
  endTime,
  roomCapacity = 0,
}) {
  const nameCharLimit = 70
  const [showScanModal, setShowScanModal] = useState(false)

  return (
    <section className="attendease-session-time-slot">
      <h2 className="attendease-time-slot-name">
        <NavLink to={`/session/${id}`}>
          {name && name.slice(0, nameCharLimit)}
          {(name.length > nameCharLimit) && '...'}
        </NavLink>

        <NavLink
          className="attendease-session-details-button"
          to={`/session/${id}`}
        >
          <Icon
            category="regular"
            type="angle-right"
          />
        </NavLink>
      </h2>

      <p>{`${venue} - ${room} (Capacity: ${getRoomCapacity(roomCapacity)})`}</p>
      <time>{`${startTime} - ${endTime}`}</time>

      <div className="d-grid gap-2">
        <Button
          variant="primary"
          size="lg"
          onClick={() => setShowScanModal(true)}
          className="attendease-time-slot-scan-button"
        >
          <Icon
            beforeText
            category="regular"
            type="qrcode"
          />
          Scan
        </Button>
      </div>

      {showScanModal && (
        <ScanSessionQrCode
          instanceId={id}
          sessionName={name}
          date={date}
          startTime={startTime}
          endTime={endTime}
          show={showScanModal}
          close={() => setShowScanModal(false)}
        />
      )}
    </section>
  )
}

SessionTimeSlot.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  venue: PropTypes.string.isRequired,
  room: PropTypes.string.isRequired,
  startTime: PropTypes.string.isRequired,
  endTime: PropTypes.string.isRequired,
  roomCapacity: PropTypes.number.isRequired,
}

export default SessionTimeSlot
