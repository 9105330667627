import { configureStore } from '@reduxjs/toolkit'
import authentication from './api/authentication'
import attendee from './api/attendee'
import modeConfig from './api/modeConfig'
import { apiSlice } from './api/apiSlice'

const store = configureStore({
  /*
    In here we hook up the API slice to our Redux store.
    Also, the "apiSlice" generates a custom middleware that needs to be added to the store.
    This middleware must be added as well - it manages cache lifetimes and expiration.
  */
  reducer: {
    authentication,
    attendee,
    modeConfig,
    [apiSlice.reducerPath]: apiSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(apiSlice.middleware),
})

export default store
