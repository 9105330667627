import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import Icon from '../Icon'

import './Spinner.scss'

const Spinner = function ({
  icon = 'circle-notch',
  spin = true,
  message = 'Loading...',
  textAlign = 'text-center',
  className,
}) {
  return (
    <div className={classnames('attendease-spinner-component', textAlign, className)}>
      <Icon
        beforeText
        category="regular"
        fixed
        spin={spin}
        type={icon}
      />
      <span>{message}</span>
    </div>
  )
}

Spinner.defaultProps = {
  icon: 'circle-notch',
  message: 'Loading...',
  textAlign: 'text-center',
  spin: true,
  className: '',
}

Spinner.propTypes = {
  icon: PropTypes.string,
  message: PropTypes.string,
  textAlign: PropTypes.string,
  spin: PropTypes.bool,
  className: PropTypes.string,
}

export default Spinner
