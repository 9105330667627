import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import Icon from '../Icon'

import './BackLink.scss'

const BackLink = function ({
  url,
  label = 'Back',
}) {
  return (
    <NavLink
      className="attendease-back-link"
      to={url}
    >
      <Icon
        beforeText
        category="regular"
        type="angle-left"
      />
      {label}
    </NavLink>
  )
}

BackLink.defaultProps = {
  label: 'Back',
}

BackLink.propTypes = {
  url: PropTypes.string.isRequired,
  label: PropTypes.string,
}

export default BackLink
