import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import {
  Row, Col, Modal,
} from 'react-bootstrap'
import {
  useCheckInTimeSlotMutation,
  useGetEventQuery,
} from '../../api/apiSlice'
import { setAttendeeId } from '../../api/attendee'
import { setIsSignedIn, setAccessCode } from '../../api/authentication'
import Icon from '../shared/Icon'
import QrCodeModule from '../shared/QrCodeModule'

import './ScanSessionQrCode.scss'

const ScanSessionQrCode = function ({
  show = false,
  close,
  sessionName,
  date,
  startTime,
  endTime,
  instanceId,
}) {
  const dispatch = useDispatch()
  const accessCode = useSelector((state) => state.authentication.accessCode)
  const {
    isError: isEventError,
    refetch,
  } = useGetEventQuery(accessCode)

  const [checkInTimeSlot, {
    isSuccess: isCheckInSuccess,
    isError: isCheckInError,
    error: checkInError,
  }] = useCheckInTimeSlotMutation()

  useEffect(() => {
    // Check if request has auth, otherwise, redirect to the login page
    if (isEventError) {
      localStorage.removeItem('accessCode')
      dispatch(setAttendeeId(null))
      dispatch(setAccessCode(''))
      dispatch(setIsSignedIn(false))
    }
  }, [isEventError])

  useEffect(() => {
    refetch()
  }, [])

  return (
    <Modal
      backdrop="static"
      className="attendease-scan-session-qrcode-modal"
      show={show}
      fullscreen
      onHide={close}
    >
      <Modal.Header closeButton>
        <Modal.Title>{sessionName}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Row
          className="flex-nowrap attendease-scan-session-qrcode-info"
        >
          <Col className="text-nowrap">
            <Icon
              beforeText
              category="light"
              className="text-blue"
              type="calendar-alt"
            />
            {date}
          </Col>
          <Col className="text-nowrap text-end">
            <Icon
              beforeText
              category="regular"
              className="text-blue"
              type="clock"
            />
            {`${startTime} - ${endTime}`}
          </Col>
        </Row>

        <QrCodeModule
          checkInAttendee={checkInTimeSlot}
          isCheckInSuccess={isCheckInSuccess}
          isCheckInError={isCheckInError}
          checkInError={checkInError || {}}
          instanceId={instanceId}
        />
      </Modal.Body>
    </Modal>
  )
}

ScanSessionQrCode.propTypes = {
  show: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  instanceId: PropTypes.string.isRequired,
  sessionName: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  startTime: PropTypes.string.isRequired,
  endTime: PropTypes.string.isRequired,
}

export default ScanSessionQrCode
