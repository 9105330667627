import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import {
  useCheckInTimeSlotMutation,
} from '../../api/apiSlice'
import CheckInButton from '../shared/CheckInButton'

const TimeSlot = function ({
  timeSlot = {},
  attendeeCheckin,
  attendeeId,
}) {
  const nameCharLimit = 70
  const eventId = useSelector((state) => state.authentication.eventId)
  const accessCode = useSelector((state) => state.authentication.accessCode)
  const [checkinDateTime, setCheckinDateTime] = useState(null)

  const [checkInTimeSlot, {
    data: checkinData,
    isSuccess: isCheckInSuccess,
    isLoading: isCheckinLoading,
  }] = useCheckInTimeSlotMutation()

  const checkInParams = {
    eventId: eventId || '',
    accessCode,
    attendeeId,
    instanceId: timeSlot.id,
  }

  useEffect(() => {
    if (isCheckInSuccess) {
      setCheckinDateTime(`${checkinData.checkin.checkin_date} ${checkinData.checkin.checkin_time}`)
    }

    return () => {
      setCheckinDateTime(null)
    }
  }, [isCheckInSuccess])

  const handleCheckin = (instanceId) => {
    const params = {
      ...checkInParams,
      instanceId,
    }
    checkInTimeSlot(params).unwrap()
    setCheckinDateTime()
  }

  return (
    <div className="attendease-attendee-time-slot">
      <div className="attendease-attendee-time-slot-info">
        <h2 className="attendease-time-slot-name">
          <NavLink to={`/session/${timeSlot.id}`}>
            {timeSlot.sessionName && timeSlot.sessionName.slice(0, nameCharLimit)}
            {(timeSlot.sessionName.length > nameCharLimit) && '...'}
          </NavLink>
        </h2>

        <p>{`${timeSlot.venueName} - ${timeSlot.roomName}`}</p>
        <time>{`${timeSlot.date} ${timeSlot.startTime} - ${timeSlot.endTime}`}</time>
      </div>

      <CheckInButton
        checkInDate={timeSlot.checkinDate ? attendeeCheckin : checkinDateTime}
        checkAttendeeIn={!timeSlot.checkinDate ? () => handleCheckin(timeSlot.id) : null}
        isCheckInSuccess={isCheckInSuccess}
        isLoading={isCheckinLoading}
      />
    </div>
  )
}

TimeSlot.propTypes = {
  timeSlot: PropTypes.PropTypes.shape({}).isRequired,
  attendeeId: PropTypes.string.isRequired,
  attendeeCheckin: PropTypes.string.isRequired,
}

export default TimeSlot
