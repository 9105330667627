import React from 'react'
import PropTypes from 'prop-types'
import CollapseInfo from '../../shared/CollapseInfo'
import Paginator from '../../shared/Paginator'
import Icon from '../../shared/Icon'
import SessionTimeSlot from '../../SessionTimeSlot'
import { formatUnixToFullDate } from '../../../utils/tools'

const TimeSlotListing = function ({
  timeSlots = [],
  pagination = {},
  page,
  setPage,
}) {
  const timeSlotDates = Array.from(new Set(timeSlots.map((timeSlot) => (
    formatUnixToFullDate(timeSlot.startTimeUnixTs)))))

  const timeSlotsByDay = (date) => (
    timeSlots.filter((timeSlot) => formatUnixToFullDate(timeSlot.startTimeUnixTs) === date)
  )

  return (
    <section className="attendease-time-slot-listing-container">
      {timeSlotDates.map((date, index) => (
        <CollapseInfo
          key={date}
          isDefaultOpen={index === 0}
          renderButtonText={() => (
            <>
              <Icon
                category="light"
                type="calendar-alt"
              />
              {date}
            </>
          )}
        >
          {timeSlotsByDay(date).map((timeSlot) => (
            <SessionTimeSlot
              key={timeSlot.id}
              id={timeSlot.id}
              name={timeSlot.sessionName}
              venue={timeSlot.venueName}
              date={formatUnixToFullDate(timeSlot.startTimeUnixTs)}
              room={timeSlot.roomName}
              startTime={timeSlot.startTime}
              endTime={timeSlot.endTime}
              roomCapacity={timeSlot.roomCapacity}
            />
          ))}
        </CollapseInfo>
      ))}

      {(pagination.pageCount > 1) && (
        <div className="mb-2">
          <Paginator page={page} pageCount={pagination.pageCount} onChange={setPage} />
        </div>
      )}
    </section>
  )
}

TimeSlotListing.propTypes = {
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  timeSlots: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  pagination: PropTypes.shape({
    pageCount: PropTypes.number.isRequired,
  }).isRequired,
}

export default TimeSlotListing
