import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import FullSizeImage from '../FullSizeImage'

import './UserAvatar.scss'

/**
 * Renders the user's name initials if a profile photo is not provided.<br/>
 * If an image URL exists, then shows the image and a button to expand it.
 */
const UserAvatar = function ({
  firstName,
  lastName,
  avatarUrl,
  size = 50,
  id,
}) {
  const [showFullImg, setShowFullImg] = useState(false)

  const styles = {
    width: `${size}px`,
    height: `${size}px`,
  }

  const renderImage = () => (
    <img
      alt={`${firstName} ${lastName}`}
      src={avatarUrl}
      className="attendease-user-avatar-img"
    />
  )

  const renderInitials = () => (
    <span className="attendease-user-initials">
      {firstName[0]}
      {lastName[0]}
    </span>
  )

  return (
    <figure
      className="attendease-user-avatar"
      style={styles}
    >
      {avatarUrl
        ? (
          <>
            {id
              ? (
                <NavLink to={`/attendee/${id}`}>
                  {renderImage()}
                </NavLink>
              )
              : renderImage()}

            <FullSizeImage
              alternateText={`${firstName} ${lastName}`}
              imageUrl={avatarUrl}
              show={showFullImg}
              setShow={setShowFullImg}
            />
          </>
        )
        : (
          <span>
            {id
              ? (
                <NavLink to={`/attendee/${id}`}>
                  {renderInitials()}
                </NavLink>
              )
              : renderInitials()}
          </span>
        )}
    </figure>
  )
}

UserAvatar.defaultProps = {
  id: '',
  size: 50,
  avatarUrl: '',
}

UserAvatar.propTypes = {
  /**
    If provided, the avatar element will be clickable.
    Clicking on it takes the user to the attendee profile page.
  */
  id: PropTypes.string,
  /**
    Attendee's first name.
  */
  firstName: PropTypes.string.isRequired,
  /**
    Attendee's last name.
  */
  lastName: PropTypes.string.isRequired,
  /**
    The size of the avatar element.
  */
  size: PropTypes.number,
  /**
    Attendee's profile picture URL.
  */
  avatarUrl: PropTypes.string,
}

export default UserAvatar
