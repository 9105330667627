import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Collapse } from 'react-bootstrap'
import classnames from 'classnames'
import Icon from '../Icon'

import './CollapseInfo.scss'

const CollapseInfo = function ({
  renderButtonText,
  isDefaultOpen = false,
  children,
}) {
  const [isOpen, setIsOpen] = useState(isDefaultOpen)

  return (
    <div className={classnames('attendease-collapse-info mb-2', { active: isOpen })}>
      <Button
        className="reset-styles attendease-collapse-button"
        onClick={() => setIsOpen(!isOpen)}
        aria-expanded={isOpen}
      >
        {renderButtonText()}

        <Icon
          category="regular"
          className="attendease-collapse-info-status"
          type={`angle-${isOpen ? 'up' : 'down'}`}
        />
      </Button>
      <Collapse in={isOpen}>
        <div className="attendease-collapse-content">
          {children}
        </div>
      </Collapse>
    </div>
  )
}

CollapseInfo.propTypes = {
  renderButtonText: PropTypes.func.isRequired,
  isDefaultOpen: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
}

export default CollapseInfo
