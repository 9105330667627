const formatUnixToFullDate = (unixTime) => {
  const milliseconds = unixTime * 1000
  const dateObject = new Date(milliseconds)

  return dateObject.toDateString()
}

const formatUnixToDate = (unixTime) => {
  const milliseconds = unixTime * 1000
  const dateObject = new Date(milliseconds)
  const options = {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  }

  return dateObject.toLocaleDateString('en-US', options)
}

const formatUnixToDateTime = (unixTime) => {
  const milliseconds = unixTime * 1000
  const dateObject = new Date(milliseconds)
  const options = {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: false,
  }

  return dateObject.toLocaleDateString('en-US', options)
}

const getRoomCapacity = (roomCapacity) => (
  (roomCapacity === 424242) ? 'Unlimited' : roomCapacity
)

export {
  formatUnixToFullDate,
  formatUnixToDate,
  formatUnixToDateTime,
  getRoomCapacity,
}
