import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import {
  useCheckInEventMutation,
} from '../../../api/apiSlice'
import UserProfile from '../../shared/UserProfile'
import CheckInButton from '../../shared/CheckInButton'

const Attendee = function ({
  attendee = {},
}) {
  const eventId = useSelector((state) => state.authentication.eventId)
  const accessCode = useSelector((state) => state.authentication.accessCode)
  const [checkinDateTime, setCheckinDateTime] = useState(null)

  const [checkInEvent, {
    data: checkInData,
    isSuccess: isCheckInSuccess,
    isLoading: isCheckinLoading,
  }] = useCheckInEventMutation()

  const checkInParams = {
    eventId: eventId || '',
    attendeeId: attendee.id,
    accessCode,
  }

  const handleCheckIn = () => {
    checkInEvent(checkInParams).unwrap()
  }

  useEffect(() => {
    if (attendee.checkinDate) {
      setCheckinDateTime(`${attendee.checkinDate} ${attendee.checkinTime}`)
    }

    if (isCheckInSuccess && !attendee.checkinDate) {
      setCheckinDateTime(`${checkInData.checkin.checkin_date} ${checkInData.checkin.checkin_time}`)
    }

    return () => {
      setCheckinDateTime(null)
    }
  }, [attendee.checkinDate, isCheckInSuccess])

  return (
    <div
      className="attendease-attendee-info d-flex"
      key={attendee.id}
    >
      <UserProfile
        id={attendee.id}
        firstName={attendee.firstName}
        lastName={attendee.lastName}
        email={attendee.email}
        avatarUrl={attendee.profileUrl}
        passName={attendee.passName}
        passGroupName={attendee.passGroupName}
        avatarSize={55}
        avatarPosition="left"
      />
      <CheckInButton
        checkInDate={checkinDateTime}
        checkAttendeeIn={!attendee.checkinDate ? () => handleCheckIn() : null}
        isCheckInSuccess={isCheckInSuccess}
        isLoading={isCheckinLoading}
      />
    </div>
  )
}

Attendee.propTypes = {
  attendee: PropTypes.shape({}).isRequired,
}

export default Attendee
