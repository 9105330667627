import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  Container,
  Button,
  Form,
  Row,
  Col,
  Navbar,
} from 'react-bootstrap'
import {
  Navigate,
} from 'react-router-dom'
import { useDispatch } from 'react-redux'
import Icon from '../../shared/Icon'
import Spinner from '../../shared/Spinner'
import { useGetEventQuery } from '../../../api/apiSlice'
import { setEventId, setAccessCode } from '../../../api/authentication'

import './Login.scss'

const Login = function ({
  signInUser,
  isSignedIn,
  modeConfig,
}) {
  const dispatch = useDispatch()
  const [accessCode, setAccessCodeState] = useState('')
  const [skipGetEvent, setSkipGetEvent] = useState(true)

  /*
    Redux-toolkit RTK-Query Tip:
    If we want to prevent a query from automatically running,
    we can use the "skip = true" parameter in a query hook.
  */
  const {
    data: event,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetEventQuery(accessCode, { skip: skipGetEvent })

  const onChangeAccessCode = (value) => {
    setSkipGetEvent(true)
    setAccessCodeState(value.toUpperCase())
  }

  useEffect(() => {
    if (isSuccess) {
      localStorage.setItem('accessCode', accessCode)
      dispatch(setAccessCode(accessCode))

      setTimeout(() => {
        signInUser(true)
        dispatch(setEventId(event.events[0].id))
      }, 100)
    }
  }, [isSuccess, accessCode])

  return (
    <section className="attendease-login-page">
      {isSignedIn && (
        <Navigate to="/" replace />
      )}

      <header className="attendease-header">
        <Navbar expand="lg">
          <Container>
            <img src={modeConfig?.images.checkin_app_logo} alt={`${modeConfig?.text.platform_name} On-site Check-in`} />
          </Container>
        </Navbar>
      </header>

      <Container fluid className="attendease-login-container">
        <Row>
          <Col lg={6} md={11}>
            <Form className="text-center">
              <Form.Group controlId="accessCodeField" className="form-group mb-3 has-icon">
                <Icon
                  category="regular"
                  type="key"
                />
                <Form.Control
                  type="text"
                  value={accessCode}
                  placeholder="Event Access Code"
                  onChange={(e) => onChangeAccessCode(e.target.value.trim())}
                />
              </Form.Group>

              <div className="d-grid gap-2">
                <Button
                  size="lg"
                  variant="success"
                  disabled={accessCode.trim() === '' || isLoading}
                  onClick={() => setSkipGetEvent(false)}
                >
                  {!isLoading
                    ? (
                      <span>Sign In</span>
                    )
                    : (
                      <span>
                        <Spinner
                          className="no-margin"
                          message="Signing In..."
                        />
                      </span>
                    )}
                </Button>
              </div>

              {isError && (
                <div className="attendease-login-page-error">
                  {error.originalStatus === 500
                    ? (
                      <div className="text-red-dark mt-2">
                        A server error has occurred, please try again.
                      </div>
                    )
                    : (
                      <div className="text-red-dark mt-2">
                        Invalid code.
                      </div>
                    )}
                </div>
              )}
            </Form>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

Login.propTypes = {
  signInUser: PropTypes.func.isRequired,
  isSignedIn: PropTypes.bool.isRequired,
  modeConfig: PropTypes.shape({
    images: PropTypes.shape({
      checkin_app_logo: PropTypes.string,
    }),
    text: PropTypes.shape({
      platform_name: PropTypes.string,
    }),
  }).isRequired,
}

export default Login
