import { useEffect } from 'react'
import {
  Routes,
  Route,
  useParams,
} from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import PrivateRoute from './components/views/PrivateRoute'
import Login from './components/views/Login'
import EventQrCode from './components/views/EventQrCode'
import TimeSlotListing from './components/views/TimeSlotListing'
import TimeSlotDetails from './components/views/TimeSlotDetails'
import AttendeeListing from './components/views/AttendeeListing'
import AttendeeDetails from './components/views/AttendeeDetails'
import { setIsSignedIn } from './api/authentication'
import { fetchModeConfig } from './api/modeConfig'
import Spinner from './components/shared/Spinner'

import 'bootstrap/dist/css/bootstrap.min.css'
import './styles/general.scss'

const App = function () {
  const dispatch = useDispatch()
  const isSignedIn = useSelector((state) => state.authentication.isSignedIn)
  const modeConfig = useSelector((state) => state.modeConfig.data)
  const modeConfigStatus = useSelector((state) => state.modeConfig.status)
  const accessCode = localStorage.getItem('accessCode')
  const handleIsSignedIn = (value) => dispatch(setIsSignedIn(value))

  const RouteParamsProvider = ({ render }) => {
    const params = useParams()
    return render(params)
  }
  const faviconTag = document.createElement('link')

  useEffect(() => {
    dispatch(fetchModeConfig())
  }, [])

  useEffect(() => {
    if (modeConfigStatus === 'success') {
      faviconTag.rel = 'shortcut icon'
      faviconTag.href = modeConfig?.images?.favicon
      document.head.appendChild(faviconTag)
    }
  }, [modeConfigStatus])

  useEffect(() => {
    if (accessCode) {
      handleIsSignedIn(true)
    } else {
      handleIsSignedIn(false)
    }
  }, [accessCode])

  if (modeConfigStatus !== 'success') {
    return (
      <div className="checkin-app-loading"><Spinner /></div>
    )
  }

  return (
    <section className={`attendease-onsite-checkin-app ${modeConfig?.mode}`}>
      <Routes>
        <Route
          path="/"
          element={(
            <PrivateRoute isSignedIn={isSignedIn}>
              <EventQrCode />
            </PrivateRoute>
          )}
        />
        <Route
          path="/session-scan"
          element={(
            <PrivateRoute isSignedIn={isSignedIn}>
              <TimeSlotListing />
            </PrivateRoute>
          )}
        />
        <Route
          path="/attendees"
          element={(
            <PrivateRoute isSignedIn={isSignedIn}>
              <AttendeeListing
                signInUser={handleIsSignedIn}
                isSignedIn={isSignedIn}
              />
            </PrivateRoute>
          )}
        />
        <Route
          path="/session/:timeSlotId"
          element={(
            <RouteParamsProvider
              render={({ timeSlotId }) => (
                <PrivateRoute isSignedIn={isSignedIn}>
                  <TimeSlotDetails timeSlotId={timeSlotId} />
                </PrivateRoute>
              )}
            />
          )}
        />
        <Route
          path="/attendee/:attendeeId"
          element={(
            <RouteParamsProvider
              render={({ attendeeId }) => (
                <PrivateRoute isSignedIn={isSignedIn}>
                  <AttendeeDetails attendeeId={attendeeId} />
                </PrivateRoute>
              )}
            />
          )}
        />
        <Route
          path="/login"
          element={(
            <Login
              signInUser={handleIsSignedIn}
              isSignedIn={isSignedIn}
              modeConfig={modeConfig}
            />
          )}
        />
      </Routes>
    </section>
  )
}

export default App
