import React from 'react'
import PropTypes from 'prop-types'
import { Modal, Button } from 'react-bootstrap'
import classnames from 'classnames'

import './Modal.scss'

/**
 * This components uses `<Modal />` from react-bootstrap.
 * See https://react-bootstrap.github.io/ for more information.
*/
const ModalContainer = function ({
  show = false,
  title = '',
  className = '',
  children,
  closeModal,
  showCloseOnHeader = true,
  closeButtonText = 'Close',
  showFooter = true,
  statusColor = 'default',
}) {
  return (
    <Modal
      backdrop="static"
      className={classnames('attendease-modal', `modal-${statusColor}`, className)}
      show={show}
      scrollable
      onHide={closeModal}
    >
      <Modal.Header closeButton={showCloseOnHeader}>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {children}
      </Modal.Body>

      {showFooter && (
        <Modal.Footer>
          <Button onClick={closeModal}>
            {closeButtonText}
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  )
}

ModalContainer.defaultProps = {
  showFooter: true,
  statusColor: 'default',
  closeButtonText: 'Close',
  showCloseOnHeader: true,
}

ModalContainer.propTypes = {
  /**
    Content that will be rendered in the modal body.
  */
  children: PropTypes.node.isRequired,
  /**
    Callback to close the modal.
  */
  closeModal: PropTypes.func.isRequired,
  /**
    Sets the modal visibility.
  */
  show: PropTypes.bool.isRequired,
  /**
    Adds a text in the modal header.
  */
  title: PropTypes.string.isRequired,
  /**
    Sets the close button visibility in the modal header.
  */
  showCloseOnHeader: PropTypes.bool,
  /**
    Sets the close button label that shows on the modal footer.
  */
  closeButtonText: PropTypes.string,
  /**
    Sets the modal footer visibility.
  */
  showFooter: PropTypes.bool,
  /**
    Sets a style for the modal based on the available Bootstrap statuses.
  */
  statusColor: PropTypes.oneOf(['default', 'info', 'warning', 'error', 'success']),
  /**
    Adds CSS classes to the `.modal` element.
  */
  className: PropTypes.string.isRequired,
}

export default ModalContainer
