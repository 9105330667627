/*
  This is a copy from /attendease - /shared/Paginator.jsx component
  Can be refactored when the time permits
*/
import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { range } from 'lodash'
import { Button } from 'react-bootstrap'
import Icon from '../Icon'

import './Paginator.scss'

/**
 * Renders a pagination component.<br/>
 * Handles page range, previous/next page and sets the active page based on the `page` props.
 * It doesn't come with buttons to jump to the first or last page.
 * Use this component for any data listing.
 */
const Paginator = function ({
  pageCount,
  padding,
  page,
  onChange,
}) {
  // The amount of optimal space we want to fill when at the start or end
  // < 1 2 3 4 5 6 7 ... 42 >
  //               ^
  //    (stretch by this amount)
  const stretchAmount = () => (padding * 2) + 2

  // Start of the current page range (center)
  // < 1 ... 4 5 6 7 8 ... 42 >
  //         ^
  const rangeStart = () => {
    const threshold = pageCount - stretchAmount()

    if (page > threshold + padding) {
      return Math.max(1, threshold)
    }
    return Math.max(1, page - padding)
  }

  // End of the current page range (center)
  // < 1 ... 4 5 6 7 8 ... 42 >
  //                 ^
  const rangeEnd = () => {
    const threshold = stretchAmount() + 1

    if (page < threshold - padding) {
      return Math.min(pageCount, threshold)
    }
    const end = page + padding
    return Math.min(pageCount, end)
  }

  // The full current page range (center)
  // < 1 ... 4 5 6 7 8 ... 42 >
  //         ^ ^ ^ ^ ^
  const pageRange = () => range(rangeStart(), rangeEnd() + 1)

  // Return true | false if we are on the first page or not
  // < 1 2 3 4 5 6 7 ... 42 >
  //   ^
  // const isFirstPage = () => {
  //   return page === 1
  // }

  // Return true | false if we are on a specific page
  // < 1 2 3 4 5 6 7 ... 42 >
  //       ^
  //  (true if 3)
  const isCurrentPage = (newPage) => page === newPage

  // Is the range detached from the left side?
  // < 1 ... 4 5 6 7 8 ... 42 >
  //      ^
  //    (true)
  const isDetachedFromLeft = () => rangeStart() > 3

  // Is the range detached from the right side?
  // < 1 ... 4 5 6 7 8 ... 42 >
  //                    ^
  //                  (true)
  const isDetachedFromRight = () => (
    rangeEnd() <= pageCount - 3
  )

  const changePage = (newPage) => {
    if (newPage > 0 && newPage <= pageCount) {
      onChange(newPage)
      window.scrollTo(0, 0)
    }
  }

  const renderPageLink = (newPage) => (
    <Button
      variant="outline-primary"
      className={classnames({ active: isCurrentPage(newPage) })}
      key={newPage}
      onClick={() => changePage(newPage)}
    >
      {newPage}
    </Button>
  )

  const renderPrevLink = () => {
    const currentPage = page - 1
    const hasPreviousPage = (currentPage > 0 && currentPage <= pageCount)

    return (
      <Button
        className={classnames({ disabled: !hasPreviousPage })}
        variant="outline-primary"
        key="prev"
        onClick={hasPreviousPage
          ? () => changePage(currentPage)
          : null}
      >
        <Icon category="regular" type="angle-left" />
      </Button>
    )
  }

  const renderNextLink = () => {
    const currentPage = page + 1
    const hasNextPage = (currentPage > 0 && currentPage <= pageCount)

    return (
      <Button
        className={classnames({ disabled: !hasNextPage })}
        variant="outline-primary"
        key="next"
        onClick={hasNextPage
          ? () => changePage(currentPage)
          : null}
      >
        <Icon
          category="regular"
          type="angle-right"
        />
      </Button>
    )
  }

  const renderLeftItems = () => {
    const items = []

    items.push(renderPrevLink())

    if (isDetachedFromLeft()) {
      items.push(renderPageLink(1))
      items.push(<div key="sep-right">…</div>)
    } else {
      range(1, rangeStart()).forEach((currentPage) => {
        items.push(renderPageLink(currentPage))
      })
    }

    return items
  }

  const renderPageRangeItems = () => pageRange().map(renderPageLink)

  const renderRightItems = () => {
    const items = []

    if (isDetachedFromRight()) {
      items.push(<div key="sep-left">…</div>)
      items.push(renderPageLink(pageCount))
    } else {
      range(rangeEnd() + 1, pageCount + 1).forEach((currentPage) => {
        items.push(renderPageLink(currentPage))
      })
    }

    items.push(renderNextLink())

    return items
  }

  return (
    <div className="attendease-paginator">
      {renderLeftItems()}
      {renderPageRangeItems()}
      {renderRightItems()}
    </div>
  )
}

Paginator.defaultProps = {
  padding: 1,
}

Paginator.propTypes = {
  /**
    Sets the current page.
  */
  page: PropTypes.number.isRequired,
  /**
    Sets the total page count.
  */
  pageCount: PropTypes.number.isRequired,
  /**
    A callback fired when the user clicks on a page number.
  */
  onChange: PropTypes.func.isRequired,
  /**
   The amount of optimal space we want to fill when at the start or end.
   For example, for a padding of `2`, the page count goes until `7` before adding the range element.
   `E.g. < 1 2 3 4 5 6 7 ... 50 >`
  */
  padding: PropTypes.number,
}

export default Paginator
