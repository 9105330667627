import React from 'react'
import PropTypes from 'prop-types'
import {
  Nav,
} from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import Icon from '../shared/Icon'

import './Navigation.scss'

const Navigation = function ({
  showSessionScan,
}) {
  return (
    <Nav className="attendease-nav">
      <NavLink to="/">
        <Icon
          category="light"
          type="qrcode"
        />
        Event Scan
      </NavLink>
      {/* Temporary solution */}
      {showSessionScan && (
        <NavLink to="/session-scan">
          <Icon
            category="light"
            type="qrcode"
          />
          Session Scan
        </NavLink>
      )}
      <NavLink to="/attendees">
        <Icon
          category="light"
          type="users"
        />
        Attendees
      </NavLink>
    </Nav>
  )
}

Navigation.propTypes = {
  showSessionScan: PropTypes.bool.isRequired,
}

export default Navigation
