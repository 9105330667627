import React from 'react'
import PropTypes from 'prop-types'
import ScanConfirmationModal from './ScanConfirmationModal'

const ScanConfirmationModalContainer = function ({
  title,
  show = false,
  close,
  status,
  statusColor,
  attendee,
  showAttendeeProfile,
  forceCheckin,
}) {
  if (!attendee) {
    return (
      <ScanConfirmationModal
        title={title}
        statusColor={statusColor}
        status={status}
        show={show}
        close={close}
      />
    )
  }

  const hasProfile = attendee.profile
  const hasRegisteringAttendee = !!attendee.registeringAttendee
  const primaryAttendeeName = hasRegisteringAttendee
    ? `${attendee.registeringAttendee.firstName} ${attendee.registeringAttendee.lastName}`
    : ''
  const primaryAttendeeEmail = hasRegisteringAttendee
    ? attendee.registeringAttendee.email
    : ''

  return (
    <ScanConfirmationModal
      firstName={attendee.firstName}
      lastName={attendee.lastName}
      avatarUrl={hasProfile ? attendee.profile.profileUrl : ''}
      passName={attendee.passName}
      passGroupName={attendee.passGroupName}
      notesTotal={attendee.notes.length}
      title={title}
      statusColor={statusColor}
      status={status}
      show={show}
      close={close}
      showAttendeeProfile={showAttendeeProfile}
      forceCheckin={forceCheckin}
      primaryAttendeeName={!attendee.selfRegistered ? primaryAttendeeName : ''}
      primaryAttendeeEmail={!attendee.selfRegistered ? primaryAttendeeEmail : ''}
    />
  )
}

ScanConfirmationModalContainer.defaultProps = {
  attendee: null,
  showAttendeeProfile: null,
  forceCheckin: null,
}

ScanConfirmationModalContainer.propTypes = {
  title: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  showAttendeeProfile: PropTypes.func,
  statusColor: PropTypes.string.isRequired,
  forceCheckin: PropTypes.func,
  attendee: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    passName: PropTypes.string,
    passGroupName: PropTypes.string,
    notes: PropTypes.arrayOf(PropTypes.shape({})),
    profile: PropTypes.shape({
      profileUrl: PropTypes.string,
    }),
    selfRegistered: PropTypes.bool,
    registeringAttendee: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      email: PropTypes.string,
    }),
  }),
}

export default ScanConfirmationModalContainer
