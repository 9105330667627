/* eslint no-param-reassign: 0 */
import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import './Icon.scss'

/**
 * This component renders an icon from the FontAwesome Pro library (v5.15.4).
 * See https://fontawesome.com/docs for more information.
 */
const Icon = function ({
  afterText,
  ariaHidden,
  beforeText,
  children,
  className,
  category = 'regular',
  inverse,
  size,
  spin,
  style,
  type,
  fixed,
  rotate,
  onClick,
}) {
  switch (category) {
    case 'regular':
      category = 'far'
      break
    case 'solid':
      category = 'fas'
      break
    case 'light':
      category = 'fal'
      break
    case 'duotone':
      category = 'fad'
      break
    case 'brand':
      category = 'fab'
      break
    default:
      category = 'far'
      break
  }

  const styles = {
    ...style,
    '--fa-rotate-angle': `${rotate}deg`,
  }

  return (
    <>
      <i
        aria-hidden={ariaHidden}
        className={
          classnames('attendease-icon', category, `fa-${type}`, {
            'fa-spin': spin,
            'fa-inverse': inverse,
            'fa-fw': fixed,
            'fa-rotate-by': rotate,
            [`fa-${size}`]: size,
            'before-text': beforeText || !!children,
            'after-text': afterText,
            'has-action': !!onClick,
          }, className)
}
        style={styles}
        onClick={onClick}
        onKeyDown={onClick}
        role="button"
        tabIndex="0"
      />
      {children}
    </>
  )
}

Icon.defaultProps = {
  beforeText: false,
  afterText: false,
  ariaHidden: true,
  onClick: null,
  className: '',
  fixed: false,
  inverse: false,
  size: 'lg',
  spin: false,
  style: null,
  category: '',
  children: null,
  rotate: 0,
}

Icon.propTypes = {
  /**
   * All available icon types are based on the FontAwesome Pro library (v5.15.4).
  */
  type: PropTypes.string.isRequired,
  /**
   * Adds a `5px` margin to the right.
  */
  afterText: PropTypes.bool,
  /**
   * Adds a `5px` margin to the left.
  */
  beforeText: PropTypes.bool,
  /**
   * Adds an `aria-hidden` attribute for accessibility.
  */
  ariaHidden: PropTypes.bool,
  /**
   * All available categories are based on the FontAwesome Pro library (v5.15.4).
  */
  category: PropTypes.oneOf(['regular', 'solid', 'light', 'duotone', 'brand']),
  /**
   * All available sizes are based on the FontAwesome Pro library (v5.15.4).
  */
  size: PropTypes.oneOf(['xs', 'sm', 'lg', '2x', '3x', '5x', '7x', '10x']),
  /**
   * Renders a component right after the icon element.
  */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  /**
   * Adds additional CSS classes in `attendease-icon` element.
  */
  className: PropTypes.string,
  /**
   * Adds a fixed width to the icon container.
  */
  fixed: PropTypes.bool,
  /**
   * Changes the icon color from dark to light.
  */
  inverse: PropTypes.bool,
  /**
   * Choose the angle in degrees that the icon will rotate (e.g. 45, 90, 180).
   * For more information see https://fontawesome.com/v6/docs/web/style/rotate#contentHeader
  */
  rotate: PropTypes.number,
  /**
   * Adds a rotate animation.
  */
  spin: PropTypes.bool,
  /**
   * Sets inline styles for the icon container.
  */
  style: PropTypes.objectOf(PropTypes.shape({})),
  /**
   * Callback fired on button click event.
  */
  onClick: PropTypes.func,
}

export default Icon
