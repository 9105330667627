import React from 'react'
import PropTypes from 'prop-types'
import Paginator from '../../shared/Paginator'
import Attendee from './Attendee'

import './AttendeeListing.scss'

const AttendeeListing = function ({
  attendees = [],
  pagination = {},
  page = 1,
  setPage,
}) {
  return (
    <section className="attendease-attendee-listing">
      <div className="attendease-attendee-listing">
        {attendees.map((attendee) => (
          <Attendee attendee={attendee} />
        ))}
      </div>

      {(pagination.pageCount > 1) && (
        <div className="mb-2">
          <Paginator page={page} pageCount={pagination.pageCount} onChange={setPage} />
        </div>
      )}
    </section>
  )
}

AttendeeListing.defaultProps = {
  attendees: [],
}

AttendeeListing.propTypes = {
  attendees: PropTypes.arrayOf(PropTypes.shape({})),
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  pagination: PropTypes.shape({
    pageCount: PropTypes.number.isRequired,
  }).isRequired,
}

export default AttendeeListing
