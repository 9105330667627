import React from 'react'
import PropTypes from 'prop-types'
import Icon from '../Icon'

import './CheckinCounter.scss'

const CheckinCounter = function ({
  total,
  current,
  className,
}) {
  function calculatePercentage(numCheckins, numScheduled) {
    if (numScheduled === 0) { return null }
    return parseFloat(((100 * numCheckins) / numScheduled).toFixed(2))
  }

  const percentage = calculatePercentage(current, total)

  return (
    <div className={`attendease-checkin-counter ${className}`}>
      <Icon
        category="solid"
        type="user-check"
        className="text-green"
        beforeText
      />
      <strong className="text-green">{current}</strong>
      &nbsp;
      {`/ ${total} (${percentage || 0}%)`}
    </div>
  )
}

CheckinCounter.defaultProps = {
  className: '',
}

CheckinCounter.propTypes = {
  total: PropTypes.number.isRequired,
  current: PropTypes.number.isRequired,
  className: PropTypes.string,
}

export default CheckinCounter
