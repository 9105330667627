import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { camelizeKeys } from 'xcase/es5'
import { Modal } from 'react-bootstrap'
import PropTypes from 'prop-types'
import QrReader from 'react-qr-reader'
import {
  useGetAttendeeQuery,
} from '../../../api/apiSlice'
import {
  setAttendeeId,
  setShowProfile,
  setIsCheckedInSuccess,
} from '../../../api/attendee'
import { setIsSignedIn, setAccessCode } from '../../../api/authentication'
import ScanConfirmationModal from '../../ScanConfirmationModal'
import Spinner from '../Spinner'
import Icon from '../Icon'
import AttendeeProfileModal from '../../AttendeeProfileModal'

const QrCodeModule = function ({
  checkInAttendee,
  isCheckInSuccess,
  isCheckInError,
  checkInError,
  instanceId,
}) {
  const dispatch = useDispatch()
  const [hasCamera, setHasCamera] = useState(null)
  const [skipGetAttendee, setSkipGetAttendee] = useState(true)
  const [showScanConfirmation, setShowScanConfirmation] = useState(false)
  const [forceCheckin, setForceCheckin] = useState(false)
  const [shouldCheckin, setShouldCheckin] = useState(false)

  const attendeeId = useSelector((state) => state.attendee.id)
  const eventId = useSelector((state) => state.authentication.eventId)
  const accessCode = useSelector((state) => state.authentication.accessCode)
  const showAttendeeProfile = useSelector((state) => state.attendee.showProfile)

  useEffect(() => {
    setSkipGetAttendee(!attendeeId)
  }, [attendeeId])

  const queryParams = {
    eventId: eventId || '',
    attendeeId,
    accessCode,
    instanceId,
  }

  const {
    data,
    isFetching: isFetchingAttendee,
    isError: isGetAttendeeError,
    isSuccess: isGetAttendeeSuccess,
    error: attendeeError,
  } = useGetAttendeeQuery(queryParams, { skip: skipGetAttendee })

  const handleError = () => {
    setHasCamera(false)
  }

  /**
    * Handle a QR Code scan
    * Exclude URL and shc:/ code
    * Check if response from QRCode is a valid attendee id
    * If valid, show modal with success message and attendee profile
    * If valid, but already checked-in, show "Already checked-in" modal
    * If not valid (user scanned any qrcode that doesn't belong to the event),
    show "Attendee not found" modal.
    * If it's a session and attendee is not scheduled, show "Check-in failed" modal.
  */
  const handleScan = (response) => {
    const isURLField = /^(?:https:)?\/\/.{3,}/.test(response)
    const isShcValue = String(response).includes('shc:/')
    const isValidValue = !isURLField && !isShcValue

    if (response) {
      dispatch(setAttendeeId(isValidValue ? response : 'not-valid'))
      setShowScanConfirmation(true)
      setShouldCheckin(!!isValidValue)
    }
  }

  /**
   * If there is a valid attendee id (state.attendee.id), the check-in
   * action will be called and will return success or error:
   * isCheckInSuccess - isCheckInError
  */
  useEffect(() => {
    if (shouldCheckin) {
      checkInAttendee(queryParams).unwrap()

      setTimeout(() => {
        setForceCheckin(false)
      }, 100)
    }
  }, [shouldCheckin])

  /*
    The session checkin endpoint doesn't do automatic checkin if
    the attendee is not scheduled to the time slot.
    If the event staff choose the "Manually checkin" option, then the
    "forceCheckin" is triggered and the attendee is successful checked in.
  */
  useEffect(() => {
    if (forceCheckin) {
      queryParams.force = true
      checkInAttendee(queryParams).unwrap()

      setTimeout(() => {
        setShouldCheckin(false)
      }, 100)
    }
  }, [forceCheckin])

  /**
   * After a successful scan, (checkInAttendee was called and returned success)
   * dispatch an action to set the checkin status (setIsCheckedInSuccess)
   * This status (state.attendee.isCheckedIn) can be used by any component.
   * E.g. after a successful scan, update some component with the checkin date/time
   * without reloading the app.
  */
  useEffect(() => {
    if (isCheckInSuccess && !isFetchingAttendee) {
      dispatch(setIsCheckedInSuccess(true))
    }

    return () => {
      dispatch(setIsCheckedInSuccess(false))
    }
  }, [isCheckInSuccess, isFetchingAttendee])

  /**
   * Check if request has auth, otherwise, redirect to the login page
   * reset all states and clean the local storage.
  */
  useEffect(() => {
    if (attendeeError && attendeeError.originalStatus === 401) {
      localStorage.removeItem('accessCode')
      dispatch(setAttendeeId(null))
      dispatch(setAccessCode(''))
      dispatch(setIsSignedIn(false))
      dispatch(setIsCheckedInSuccess(false))
    }
  }, [attendeeError])

  const handleShowAttendeeProfile = () => {
    setShowScanConfirmation(false)
    dispatch(setShowProfile(true))
  }

  const closeScanModal = () => {
    setShowScanConfirmation(false)
    setForceCheckin(false)
    setShouldCheckin(false)
    dispatch(setAttendeeId(null))
    dispatch(setIsCheckedInSuccess(false))
  }

  const handleCheckInProfile = () => {
    setForceCheckin(true)
    setShowScanConfirmation(true)
  }

  // TODO: temporary function to handle errors while API
  // doesn't return error messaging for all status
  // Remove this when all error messages are done
  const error = () => {
    if (checkInError.data) {
      if (checkInError.status === 403) {
        return checkInError.data.errors
      }
    }

    return []
  }

  const isNotScheduled = isGetAttendeeSuccess
    && !isFetchingAttendee
    && isCheckInError
    && (error()[0] === 'Attendee not scheduled')

  return (
    <section className="attendease-qr-code-module">
      {(hasCamera === false) && (
        <p className="text-red-dark">
          <Icon
            beforeText
            category="solid"
            type="exclamation-triangle"
          />
          No camera was found.
        </p>
      )}

      <QrReader
        className="attendease-qr-reader"
        delay={300}
        onError={handleError}
        onScan={handleScan}
      />

      <Modal
        show={isFetchingAttendee}
        className="attendease-loading-modal"
      >
        <Modal.Body>
          <Spinner />
        </Modal.Body>
      </Modal>

      {(isGetAttendeeSuccess && isCheckInSuccess && !isFetchingAttendee) && (
        <ScanConfirmationModal
          title="Successful Check-in!"
          status="success"
          statusColor="success"
          show={showScanConfirmation}
          attendee={camelizeKeys(data.attendee)}
          close={closeScanModal}
          showAttendeeProfile={handleShowAttendeeProfile}
        />
      )}

      {(isGetAttendeeSuccess && isCheckInError && !isFetchingAttendee) && (
        <>
          {(error()[0] === 'Attendee not scheduled') && (
            <ScanConfirmationModal
              title="Not Scheduled"
              status="failed"
              statusColor="warning"
              show={showScanConfirmation}
              attendee={camelizeKeys(data.attendee)}
              close={closeScanModal}
              showAttendeeProfile={handleShowAttendeeProfile}
              forceCheckin={setForceCheckin}
            />
          )}

          {(error()[0] === 'Attendee already checked in') && (
            <ScanConfirmationModal
              title="Already checked in"
              status="checkedin"
              statusColor="info"
              show={showScanConfirmation}
              attendee={camelizeKeys(data.attendee)}
              close={closeScanModal}
              showAttendeeProfile={handleShowAttendeeProfile}
            />
          )}

          {(checkInError.originalStatus === 500) && (
            <ScanConfirmationModal
              title="Something went wrong"
              status="error"
              statusColor="error"
              show={showScanConfirmation}
              attendee={null}
              close={closeScanModal}
            />
          )}
        </>
      )}

      {isGetAttendeeError && (
        <>
          {(attendeeError.status === 404) && (
            <ScanConfirmationModal
              title="Not Found!"
              status="notFound"
              statusColor="info"
              show={showScanConfirmation}
              attendee={null}
              close={closeScanModal}
            />
          )}

          {(attendeeError.originalStatus === 500) && (
            <ScanConfirmationModal
              title="Something went wrong"
              status="error"
              statusColor="error"
              show={showScanConfirmation}
              attendee={null}
              close={closeScanModal}
            />
          )}
        </>
      )}

      {isGetAttendeeSuccess && (
        <AttendeeProfileModal
          attendee={camelizeKeys(data.attendee)}
          show={showAttendeeProfile}
          close={closeScanModal}
          checkInAttendee={isNotScheduled ? handleCheckInProfile : null}
        />
      )}
    </section>
  )
}

QrCodeModule.defaultProps = {
  checkInError: {},
  instanceId: null,
}

QrCodeModule.propTypes = {
  checkInAttendee: PropTypes.func.isRequired,
  isCheckInSuccess: PropTypes.bool.isRequired,
  isCheckInError: PropTypes.bool.isRequired,
  checkInError: PropTypes.shape({
    status: PropTypes.number,
    originalStatus: PropTypes.number,
    data: PropTypes.shape({
      errors: PropTypes.arrayOf(PropTypes.string),
    }),
  }),
  instanceId: PropTypes.string,
}

export default QrCodeModule
