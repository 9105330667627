/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

const initialState = {
  data: {
    images: {},
    text: {},
    urls: {},
  },
}

export const fetchModeConfig = createAsyncThunk('modeConfig/fetchData', async () => (
  /*
    It accepts the {window.location.hostname} and the possible values are:
    - localhost.checkin.attendease.com
    - localhost.checkin.tripleseattickets.com

    ** If starting the app with npm start, it will open "localhost:3100" and the API won't work.
  */
  fetch(`${process.env.REACT_APP_API_URL}/api/application_modes?by_hostname=${window.location.hostname}`)
    .then((response) => (
      response.json().then((json) => (
        response.ok ? json : Promise.reject(json)
      ))
    ))
    .then((data) => data)
    .catch((error) => console.log('error', error))
))

export const modeConfigSlice = createSlice({
  name: 'modeConfig',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(fetchModeConfig.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchModeConfig.fulfilled, (state, action) => {
        state.status = 'success'
        state.data = action.payload
      })
      .addCase(fetchModeConfig.rejected, (state) => {
        state.status = 'error'
        state.error = 'Error when loading the data.'
      })
  },
})

export const { getModeData } = modeConfigSlice.actions

export default modeConfigSlice.reducer
