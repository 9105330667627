import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { camelizeKeys } from 'xcase/es5'
import {
  useGetAttendeeQuery,
  useCheckInEventMutation,
} from '../../../api/apiSlice'
import { formatUnixToDateTime } from '../../../utils/tools'
import Spinner from '../../shared/Spinner'
import PageContainer from '../../shared/PageContainer'
import AttendeeDetails from './AttendeeDetails'

const AttendeeDetailsContainer = function ({
  attendeeId,
}) {
  const [checkinDateTime, setCheckinDateTime] = useState(null)
  const eventId = useSelector((state) => state.authentication.eventId)
  const accessCode = useSelector((state) => state.authentication.accessCode)
  const modeConfigName = useSelector((state) => state.modeConfig?.data?.mode)

  const queryParams = {
    eventId: eventId || '',
    attendeeId,
    accessCode,
  }

  const {
    data,
    isLoading,
    isFetching,
    isError,
    isSuccess,
    // error,
    refetch,
  } = useGetAttendeeQuery(queryParams, { skip: !eventId })

  const [checkInEvent, {
    isSuccess: isCheckInSuccess,
    isLoading: isCheckinLoading,
    data: checkInData,
  }] = useCheckInEventMutation()

  const checkInParams = {
    eventId: eventId || '',
    attendeeId: isSuccess ? data.attendee.id : null,
    accessCode,
  }

  useEffect(() => {
    refetch()
  }, [])

  const handleCheckIn = () => {
    checkInEvent(checkInParams).unwrap()
  }

  useEffect(() => {
    if (isSuccess && !isFetching) {
      const eventCheckins = data.attendee.checkins.filter((checkin) => checkin.type === 'Event')
      const getNewestCheckin = (prev, current) => Math.max(prev, current.checkin_time_unix_ts)
      const attendeeEventCheckin = eventCheckins.reduce(getNewestCheckin, 0)

      if (attendeeEventCheckin) {
        setCheckinDateTime(formatUnixToDateTime(attendeeEventCheckin))
      }

      if (isCheckInSuccess && (attendeeEventCheckin === 0)) {
        setCheckinDateTime(`${checkInData.checkin.checkin_date} ${checkInData.checkin.checkin_time}`)
      }
    }

    return () => {
      setCheckinDateTime(null)
    }
  }, [isSuccess, isCheckInSuccess, isFetching])

  return (
    <PageContainer>
      <section className="attendease-attendee-details-page">
        {(isLoading || isFetching) && (
          <Spinner />
        )}

        {isError && (
          <div className="text-red-dark">
            There was an error when trying to fetch the data.
          </div>
        )}

        {(isSuccess && !isFetching) && (
          <AttendeeDetails
            attendee={camelizeKeys(data.attendee)}
            checkInEvent={handleCheckIn}
            checkinDateTime={checkinDateTime}
            isCheckInSuccess={isCheckInSuccess}
            isCheckinLoading={isCheckinLoading}
            showSchedule={modeConfigName === 'attendease'} // temporary solution
          />
        )}
      </section>
    </PageContainer>
  )
}

AttendeeDetailsContainer.propTypes = {
  attendeeId: PropTypes.string.isRequired,
}

export default AttendeeDetailsContainer
