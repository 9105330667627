import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'react-bootstrap'
import Modal from '../shared/Modal'
import UserProfile from '../shared/UserProfile'
import AttendeeInfo from '../AttendeeInfo'

import './AttendeeProfileModal.scss'

/**
  A modal that displays the attendee profile, additional information,
  and a button to check-in into the event or session.
*/
const AttendeeProfileModal = function ({
  show = false,
  attendee,
  hasCheckIn,
  closeModal,
  handleCheckin,
}) {
  if (!attendee) {
    return null
  }

  const hasProfile = attendee.profile
  const hasRegisteringAttendee = !!attendee.registeringAttendee
  const primaryAttendeeName = hasRegisteringAttendee
    ? `${attendee.registeringAttendee.firstName} ${attendee.registeringAttendee.lastName}`
    : ''
  const primaryAttendeeEmail = hasRegisteringAttendee
    ? attendee.registeringAttendee.email
    : ''

  return (
    <Modal
      show={show}
      title="Attendee Profile"
      closeModal={closeModal}
      closeButtonText="Back to Scan"
      className="attendease-attendee-profile-modal"
    >
      <UserProfile
        firstName={attendee.firstName}
        lastName={attendee.lastName}
        avatarUrl={hasProfile ? attendee.profile.profileUrl : ''}
        passName={attendee.passName}
        passGroupName={attendee.passGroupName}
        avatarSize={65}
        avatarPosition="left"
      />

      {hasCheckIn && (
        <Button
          className="mb-4"
          variant="success"
          onClick={handleCheckin}
        >
          Manually Check-in
        </Button>
      )}

      <AttendeeInfo
        email={attendee.email}
        company={hasProfile ? attendee.profile.company : ''}
        jobTitle={hasProfile ? attendee.profile.title : ''}
        facebook={hasProfile ? attendee.profile.facebook : ''}
        instagram={hasProfile ? attendee.profile.instagram : ''}
        linkedin={hasProfile ? attendee.profile.linkedin : ''}
        twitter={hasProfile ? attendee.profile.twitter : ''}
        notes={attendee.notes}
        primaryAttendeeName={!attendee.selfRegistered ? primaryAttendeeName : ''}
        primaryAttendeeEmail={!attendee.selfRegistered ? primaryAttendeeEmail : ''}
      />
    </Modal>
  )
}

AttendeeProfileModal.defaultProps = {
  show: false,
  hasCheckIn: false,
  attendee: {
    firstName: '',
    registering_attendee: null,
    profile: {},
  },
  handleCheckin: null,
}

AttendeeProfileModal.propTypes = {
  /**
    Sets the modal visibility.
  */
  show: PropTypes.bool,
  /**
    A function to hide the modal.
  */
  closeModal: PropTypes.func.isRequired,
  /**
    A function to manually check-in an attendee into the event or session.
  */
  handleCheckin: PropTypes.func,
  /**
    If true, it will show a "Manually check-in" button.
  */
  hasCheckIn: PropTypes.bool,
  /**
    Attendee information.
  */
  attendee: PropTypes.shape({
    id: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    passName: PropTypes.string,
    email: PropTypes.string,
    passGroupName: PropTypes.string,
    selfRegistered: PropTypes.bool,
    registeringAttendee: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      email: PropTypes.string,
    }),
    profile: PropTypes.shape({
      profileUrl: PropTypes.string,
      company: PropTypes.string,
      title: PropTypes.string,
      facebook: PropTypes.string,
      linkedin: PropTypes.string,
      instagram: PropTypes.string,
      twitter: PropTypes.string,
    }),
    notes: PropTypes.arrayOf(PropTypes.shape({})),
  }),
}

export default AttendeeProfileModal
