import React from 'react'
import PropTypes from 'prop-types'
import { Fade } from 'react-bootstrap'
import Icon from '../Icon'

import './FullSizeImage.scss'

/**
 * Renders an image at full size in a full screen window.
 * It can be dismissed by `setShow` function.
*/
const FullSizeImage = function ({
  alternateText,
  imageUrl,
  show,
  setShow,
}) {
  return (
    <div className="attendease-full-size-image">
      <Icon
        category="solid"
        type="expand-alt"
        className="attendease-full-size-image-open"
        onClick={() => setShow(true)}
      />

      <Fade
        in={show}
        mountOnEnter
        unmountOnExit
      >
        <div className="attendease-full-size-image-window">
          <Icon
            category="regular"
            type="times"
            className="attendease-full-size-image-close"
            onClick={() => setShow(false)}
          />

          <img
            alt={alternateText}
            src={imageUrl}
          />
        </div>
      </Fade>
    </div>
  )
}

FullSizeImage.defaultProps = {
  show: false,
}

FullSizeImage.propTypes = {
  show: PropTypes.bool,
  alternateText: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  setShow: PropTypes.func.isRequired,
}

export default FullSizeImage
