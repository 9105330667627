import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'react-bootstrap'
import Modal from '../shared/Modal'
import Icon from '../shared/Icon'
import UserProfile from '../shared/UserProfile'

import './ScanConfirmationModal.scss'

/**
  A modal that displays a confirmation message
  and user information after doing a QR Code scan.
*/
const ScanConfirmationModal = function ({
  firstName,
  lastName,
  avatarUrl,
  passName,
  passGroupName,
  title,
  show = false,
  status,
  statusColor,
  close,
  showAttendeeProfile,
  forceCheckin,
  notesTotal,
  primaryAttendeeName,
  primaryAttendeeEmail,
}) {
  return (
    <Modal
      show={show}
      title={title}
      className="attendease-scan-confirmation-modal"
      closeModal={close}
      closeButtonText="Close"
      showCloseOnHeader={false}
      statusColor={statusColor}
    >
      {(status === 'success' || status === 'failed' || status === 'checkedin') && (
        <div className="text-center">
          <UserProfile
            firstName={firstName}
            lastName={lastName}
            avatarUrl={avatarUrl}
            passName={passName}
            passGroupName={passGroupName}
            avatarSize={110}
          />

          <p className={`attendease-scan-notes-total ${primaryAttendeeEmail ? 'mb-1' : ''}`}>
            {`Notes (${notesTotal})`}
          </p>

          {primaryAttendeeEmail && (
            <p className="attendease-scan-registered-by">
              {`Registered by: ${primaryAttendeeName} (${primaryAttendeeEmail})`}
            </p>
          )}

          {status === 'failed' && (
            <Button
              variant="success"
              onClick={() => forceCheckin(true)}
            >
              Manually Check-in
            </Button>
          )}

          <Button
            variant="primary"
            onClick={showAttendeeProfile}
          >
            View Profile
          </Button>
        </div>
      )}

      {(status === 'notFound') && (
        <div className="attendease-scan-attendee-not-found text-center">
          <Icon
            category="solid"
            className="mb-2 text-blue-light"
            type="exclamation-circle"
            size="3x"
          />
          <h2 className="h2 mb-2">Attendee not found</h2>
          <p className="help-block">
            The scanned QR code doesn’t belong to any registered attendee for this event.
          </p>
        </div>
      )}

      {(status === 'error') && (
        <div className="attendease-scan-attendee-not-found text-center">
          <Icon
            category="solid"
            className="mb-2 text-red-dark"
            type="exclamation-triangle"
            size="2x"
          />
          <p className="help-block">A server error has occurred, please try again.</p>
        </div>
      )}
    </Modal>
  )
}

ScanConfirmationModal.defaultProps = {
  firstName: '',
  lastName: '',
  avatarUrl: '',
  passName: '',
  passGroupName: '',
  showAttendeeProfile: null,
  forceCheckin: null,
  notesTotal: 0,
  primaryAttendeeName: '',
  primaryAttendeeEmail: '',
}

ScanConfirmationModal.propTypes = {
  /**
    A text that will show on the modal header.
  */
  title: PropTypes.string.isRequired,
  /**
    Sets what kind of content the component will show inside of the modal body.
  */
  status: PropTypes.oneOf(['success', 'failed', 'error', 'notFound', 'checkedin']).isRequired,
  /**
    Changes the modal header background color.
  */
  statusColor: PropTypes.oneOf(['success', 'warning', 'error', 'info', 'default']).isRequired,
  /**
    Attendee's first name. First letter of the string is also used
    to show name initials if a photo is not provided.
  */
  firstName: PropTypes.string,
  /**
    Attendee's last name. First letter of the string is also used
    to show name initials if a photo is not provided.
  */
  lastName: PropTypes.string,
  /**
    Attendee's profile photo in a URL format.
  */
  avatarUrl: PropTypes.string,
  /**
    Attendee's pass name.
  */
  passName: PropTypes.string,
  /**
    Attendee's pass group name.
  */
  passGroupName: PropTypes.string,
  /**
    Sets the modal visibility.
  */
  show: PropTypes.bool.isRequired,
  /**
    Function to hide the modal.
  */
  close: PropTypes.func.isRequired,
  /**
    Show/hide the "View Profile" button.
  */
  showAttendeeProfile: PropTypes.func,
  /**
    Show the total notes that an attendee profile has.
  */
  notesTotal: PropTypes.number,
  /**
    A function to manually check-in an attendee into the event or session.
  */
  forceCheckin: PropTypes.func,
  /**
    If this is an additional attendee, then a "Registered by" section will
    show with the primary attendee name.
  */
  primaryAttendeeName: PropTypes.string,
  /**
    If this is an additional attendee, then a "Registered by" section will
    show with the primary attendee email.
  */
  primaryAttendeeEmail: PropTypes.string,
}

export default ScanConfirmationModal
