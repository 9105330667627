import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

/*
  In here we define our single API slice object.
  The application is expected to have only one "createApi()" call in it. This one API slice
  should contain all endpoint definitions that talk to the same base URL
  (e.g. https://dashboard.attendease.com/api).
  For example, endpoints /api/events and /api/sessions are both fetching data from the same server,
  so they would go in the same API slice.
  * If the app does fetch data from multiple servers, you can either specify full URLs in
  each endpoint, or if necessary create separate API slices for each server.
  * Endpoints are normally defined directly inside the "createApi()" call. To split up the endpoints
  between multiple files, we  can use the "apiSlice.injectEndpoints()" method.

  More information:
  https://redux-toolkit.js.org/rtk-query/overview
  https://redux.js.org/tutorials/essentials/part-7-rtk-query-basics
  https://redux.js.org/tutorials/essentials/part-8-rtk-query-advanced#injecting-endpoints
*/

export const apiSlice = createApi({
  // The cache reducer expects to be added at `state.api` (already default - this is optional)
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}/api/checkin_app`,
    prepareHeaders: (headers) => {
      headers.set('Content-Type', 'application/json')
      headers.set('X-Requested-With', 'onsite-checkin')

      return headers
    },
  }),
  tagTypes: ['Attendee, Attendees'],
  endpoints: (builder) => ({
    /*
      As an example, `getSessions` endpoint is a "query" operation that returns data.
      By default, query endpoints will use a GET HTTP request, but you can override
      that by returning an object like {url: '/sessions', method: 'POST', body: response}.
      *You can also define several other options for the request this way, such as setting headers.
      *Use "builder.mutation()" to send updates to the server (e.g. POST/PUT methods)
      *RTK Query lets us define relationships between queries and mutations to
      enable automatic data refetching, using "tags".
      A "tag" is a string or small object that lets you name certain types of data,
      and invalidate portions of the cache. When a cache tag is invalidated,
      RTK Query will automatically refetch the endpoints that were marked with that tag.
    */
    getEvent: builder.query({
      query: (accessCode) => ({
        url: '/events/',
        method: 'GET',
        headers: {
          'x-event-access-code': accessCode,
        },
      }),
    }),
    getSessions: builder.query({
      query: ({
        eventId = '', page = 1, searchTerm = '', accessCode, attendeeId = '',
      }) => ({
        url: `/events/${eventId}/instances?page=${page}&search_term=${searchTerm}&attendee_id=${attendeeId}`,
        method: 'GET',
        headers: {
          'x-event-access-code': accessCode,
        },
      }),
      keepUnusedDataFor: 5,
    }),
    getTimeSlot: builder.query({
      query: ({ eventId, timeSlotId, accessCode }) => ({
        url: `/events/${eventId}/instances/${timeSlotId}?include_scheduled_attendees_count=true&include_checked_in_attendees_count=true`,
        method: 'GET',
        headers: {
          'x-event-access-code': accessCode,
        },
      }),
    }),
    getAttendees: builder.query({
      query: ({
        eventId, accessCode, page = 1, searchTerm = '', filterBy = '', timeSlotId = '',
      }) => {
        const url = `/events/${eventId}/attendees?page=${page}&search_term=${searchTerm}&instance_id=${timeSlotId}`

        // Only send `checked_in` param if true/false.
        // "checked_in=true" returns only checked-in attendees
        // "checked_in=false" returns only "not checked-in" attendees
        // Not sending this param will return all attendees.
        const isCheckedInFilter = () => {
          switch (filterBy) {
            case 'all':
              return null

            case 'checked_in':
              return true

            case 'not_checked_in':
              return false

            default:
              return null
          }
        }

        const renderFilterTerm = `${url}&checked_in=${isCheckedInFilter()}`

        return ({
          url: (isCheckedInFilter() !== null) ? renderFilterTerm : url,
          method: 'GET',
          headers: {
            'x-event-access-code': accessCode,
          },
        })
      },
      providesTags: ['Attendees'],
    }),
    getAttendee: builder.query({
      query: ({ eventId, attendeeId, accessCode }) => ({
        url: `/events/${eventId}/attendees/${attendeeId}/`,
        method: 'GET',
        headers: {
          'x-event-access-code': accessCode,
        },
      }),
      providesTags: ['Attendee'],
    }),
    checkInEvent: builder.mutation({
      query: ({ eventId, attendeeId, accessCode }) => ({
        url: `/events/${eventId}/checkins/`,
        method: 'POST',
        body: {
          checkin: {
            attendee_id: attendeeId,
          },
        },
        headers: {
          'x-event-access-code': accessCode,
        },
      }),
    }),
    checkInTimeSlot: builder.mutation({
      query: ({
        eventId, attendeeId, instanceId, accessCode, force = false,
      }) => ({
        url: `/events/${eventId}/instances/${instanceId}/checkins`,
        method: 'POST',
        body: {
          force,
          checkin: {
            attendee_id: attendeeId,
          },
        },
        headers: {
          'x-event-access-code': accessCode,
        },
      }),
    }),
  }),
})

/*
  RTK Query's React integration will automatically generate React hooks for every endpoint we have.
  Those hooks encapsulate the process of triggering a request when a component mounts,
  and re-rendering the component as the request is processed and data is available.
  We can export those hooks out of this API slice file for use in our React components.
  The hooks are automatically named based on a standard convention:
    - "use", the normal prefix for any React hook
    - The name of the endpoint, capitalized
    - The type of the endpoint, Query or Mutation
  So, if your "query" endpoint is "getSessions", the hook will be called "useGetSessionsQuery"
  *These hooks replace the usage of "useSelector", "useDispatch", and "useEffect"
  * They also come by default with these status: isFetching, isLoading, isSuccess, isError, error
*/
export const {
  useGetEventQuery,
  useGetSessionsQuery,
  useGetTimeSlotQuery,
  useGetAttendeesQuery,
  useGetAttendeeQuery,
  useCheckInEventMutation,
  useCheckInTimeSlotMutation,
} = apiSlice
