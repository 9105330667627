import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { setShowProfile } from '../../api/attendee'
import AttendeeProfileModal from './AttendeeProfileModal'

const AttendeeProfileModalContainer = function ({
  attendee,
  show,
  close,
  checkInAttendee,
}) {
  const dispatch = useDispatch()

  const hasCheckIn = !!checkInAttendee

  const closeModal = () => {
    dispatch(setShowProfile(false))
    close()
  }

  const handleCheckin = () => {
    checkInAttendee()
    dispatch(setShowProfile(false))
  }

  return (
    <AttendeeProfileModal
      dispatch={dispatch}
      attendee={attendee}
      show={show}
      closeModal={closeModal}
      hasCheckIn={hasCheckIn}
      handleCheckin={handleCheckin}
    />
  )
}

AttendeeProfileModalContainer.defaultProps = {
  show: false,
  checkInAttendee: null,
  attendee: {},
}

AttendeeProfileModalContainer.propTypes = {
  show: PropTypes.bool,
  close: PropTypes.func.isRequired,
  checkInAttendee: PropTypes.func,
  attendee: PropTypes.shape({}),
}

export default AttendeeProfileModalContainer
