import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import classnames from 'classnames'
import UserAvatar from '../UserAvatar'
import Icon from '../Icon'

import './UserProfile.scss'

/**
 * Renders user's avatar component along with user's information.
 */
const UserProfile = function ({
  id,
  firstName,
  lastName,
  email,
  avatarUrl,
  passName,
  passGroupName,
  avatarSize = 110,
  avatarPosition = 'top',
}) {
  const renderAttendeeName = () => (
    <p className="attendease-user-profile-name">
      {`${firstName} ${lastName}`}
    </p>
  )
  return (
    <section className={classnames('attendease-user-profile', `avatar-position-${avatarPosition}`)}>
      <UserAvatar
        id={id}
        avatarUrl={avatarUrl}
        firstName={firstName || ''}
        lastName={lastName || ''}
        size={avatarSize}
      />

      <div className="attendease-user-profile-info">
        {id
          ? (
            <NavLink to={`/attendee/${id}`}>
              {renderAttendeeName()}
            </NavLink>
          )
          : renderAttendeeName()}

        <p className="attendease-user-profile-email">
          {email}
        </p>

        <p className="attendease-user-profile-pass">
          <Icon
            beforeText
            category="solid"
            type="ticket"
            rotate={-45}
          />
          {`${passName}, ${passGroupName}`}
        </p>
      </div>
    </section>
  )
}

UserProfile.defaultProps = {
  id: '',
  email: '',
  avatarPosition: 'top',
  avatarUrl: '',
  avatarSize: 110,
  passName: '',
  passGroupName: '',
}

UserProfile.propTypes = {
  /**
    If provided, the attendee's name will be clickable.
    Clicking on it takes the user to the attendee profile page.
  */
  id: PropTypes.string,
  /**
    Attendee's first name.
  */
  firstName: PropTypes.string.isRequired,
  /**
    Attendee's last name.
  */
  lastName: PropTypes.string.isRequired,
  /**
    Attendee's e-mail.
  */
  email: PropTypes.string,
  /**
    Change the position of the user's avatar.
  */
  avatarPosition: PropTypes.oneOf(['top', 'left']),
  /**
    User's profile picture URL.
  */
  avatarUrl: PropTypes.string,
  /**
    The size of the avatar element in pixel.
  */
  avatarSize: PropTypes.number,
  /**
    Attendee's pass name.
  */
  passName: PropTypes.string,
  /**
    Attendee's pass group name.
  */
  passGroupName: PropTypes.string,
}

export default UserProfile
