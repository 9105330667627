import React from 'react'
import PropTypes from 'prop-types'
import {
  Tabs,
  Tab,
} from 'react-bootstrap'
import SocialProfiles from '../shared/SocialProfiles'
import AttendeeTimeSlots from '../AttendeeTimeSlots'
import { formatUnixToDate } from '../../utils/tools'

import './AttendeeInfo.scss'

/**
  A Tabs component that renders the attendee information such as
  profile info, schedule and event managers' notes.
*/
const AttendeeInfo = function ({
  id,
  email = '',
  company = '',
  jobTitle = '',
  facebook = '',
  instagram = '',
  linkedin = '',
  twitter = '',
  notes,
  showSchedule,
  primaryAttendeeName,
  primaryAttendeeEmail,
}) {
  const hasSocialProfiles = facebook || instagram || linkedin || twitter

  return (
    <Tabs className="attendease-tabs" defaultActiveKey={showSchedule ? 1 : 2} id="attendeeProfileTabs">
      {showSchedule && (
        <Tab eventKey={1} title="Schedule">
          <AttendeeTimeSlots
            attendeeId={id}
            checkAttendeeIn={() => console.log('checkin')}
          />
        </Tab>
      )}

      <Tab eventKey={2} title="More Info">
        {primaryAttendeeEmail && (
          <section className="mb-2">
            <p className="mb-1">
              <strong>Registered by:</strong>
            </p>
            <p>{`${primaryAttendeeName} (${primaryAttendeeEmail})`}</p>
          </section>
        )}
        <section className="mb-2">
          <p className="mb-1">
            <strong>E-mail:</strong>
          </p>
          <p>{email || 'N/A'}</p>
        </section>

        <section className="mb-2">
          <p className="mb-1">
            <strong>Company:</strong>
          </p>
          <p>{company || 'N/A'}</p>
        </section>

        <section className="mb-2">
          <p className="mb-1">
            <strong>Job Title:</strong>
          </p>
          <p>{jobTitle || 'N/A'}</p>
        </section>

        <section className="mb-2">
          <p className="mb-1">
            <strong>Social Profiles:</strong>
          </p>
          {hasSocialProfiles
            ? (
              <SocialProfiles
                facebook={facebook}
                instagram={instagram}
                linkedin={linkedin}
                twitter={twitter}
              />
            )
            : (
              <span>N/A</span>
            )}
        </section>
      </Tab>

      <Tab eventKey={3} title={`Notes (${notes.length})`}>
        <div className="attendease-attendee-notes">
          {(notes.length === 0) && (
            <p>No notes found.</p>
          )}

          {(notes.length > 0) && notes.map((note) => (
            <div className="attendease-attendee-notes-item" key={note.id}>
              <p className="mb-1">
                <strong>{note.authorName}</strong>
                <time>
                  {` - ${formatUnixToDate(note.postTimeUnixTs)}, ${note.postTime}`}
                </time>
              </p>
              <p>{note.body}</p>
            </div>
          ))}
        </div>
      </Tab>
    </Tabs>
  )
}

AttendeeInfo.defaultProps = {
  id: '',
  email: '',
  company: '',
  jobTitle: '',
  facebook: '',
  instagram: '',
  linkedin: '',
  twitter: '',
  notes: [],
  primaryAttendeeName: '',
  primaryAttendeeEmail: '',
  showSchedule: false,
}

AttendeeInfo.propTypes = {
  /**
    The attendee's id.
  */
  id: PropTypes.string,
  /**
    The attendee's e-mail. If nothing is provided,
    the component will show "N/A".
  */
  email: PropTypes.string,
  /**
    The attendee's Company name. If nothing is provided,
    the component will show "N/A".
  */
  company: PropTypes.string,
  /**
    The attendee's job title. If nothing is provided,
    the component will show "N/A".
  */
  jobTitle: PropTypes.string,
  /**
    The attendee's Facebook profile URL or username. If nothing is provided,
    the component will hide the icon.
  */
  facebook: PropTypes.string,
  /**
    The attendee's Instagram profile URL or username. If nothing is provided,
    the component will hide the icon.
  */
  instagram: PropTypes.string,
  /**
    The attendee's LinkedIn profile URL or username. If nothing is provided,
    the component will hide the icon.
  */
  linkedin: PropTypes.string,
  /**
    The attendee's Twitter profile URL or username. If nothing is provided,
    the component will hide the icon.
  */
  twitter: PropTypes.string,
  /**
    A list of notes left by event managers for the attendee.
  */
  notes: PropTypes.arrayOf(PropTypes.shape({
    authorName: PropTypes.string,
    postTimeUnixTs: PropTypes.number,
    postTime: PropTypes.string,
    body: PropTypes.string,
  })),
  /**
    Sets the Schedule tab visilibity.
  */
  showSchedule: PropTypes.bool,
  /**
    If this is an additional attendee, then a "Registered by" section will
    show with the primary attendee name.
  */
  primaryAttendeeName: PropTypes.string,
  /**
    If this is an additional attendee, then a "Registered by" section will
    show with the primary attendee email.
  */
  primaryAttendeeEmail: PropTypes.string,
}

export default AttendeeInfo
