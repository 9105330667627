import React from 'react'
import PropTypes from 'prop-types'
import SessionTimeSlot from './SessionTimeSlot'

const SessionTimeSlotContainer = function ({
  id,
  name,
  venue,
  room,
  date,
  startTime,
  endTime,
  roomCapacity,
}) {
  return (
    <SessionTimeSlot
      id={id}
      name={name}
      venue={venue}
      room={room}
      date={date}
      startTime={startTime}
      endTime={endTime}
      roomCapacity={roomCapacity}
    />
  )
}

SessionTimeSlotContainer.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  venue: PropTypes.string.isRequired,
  room: PropTypes.string.isRequired,
  startTime: PropTypes.string.isRequired,
  endTime: PropTypes.string.isRequired,
  roomCapacity: PropTypes.number.isRequired,
}

export default SessionTimeSlotContainer
