/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react'
import Select, { components } from 'react-select'
import { useSelector } from 'react-redux'
import { camelizeKeys } from 'xcase/es5'
import { useGetAttendeesQuery } from '../../../api/apiSlice'
import { useAccessCodeError } from '../../../hooks'
import Spinner from '../../shared/Spinner'
import SearchField from '../../shared/SearchField'
import PageContainer from '../../shared/PageContainer'
import AttendeeListing from './AttendeeListing'
import ReloadButton from '../../shared/ReloadButton/ReloadButton'

const renderSelected = (props) => (
  <components.ValueContainer {...props}>
    <span className="selected-label">{`Filter by: ${props.getValue()[0].label}`}</span>
  </components.ValueContainer>
)

const AttendeeListingContainer = function () {
  const [page, setPage] = useState(1)
  const [searchTerm, setSearchTerm] = useState('')
  const [filterBy, setFilterBy] = useState({ label: 'All', value: 'all' })
  const [filteredSearchTerm, setFilteredSearchTerm] = useState(searchTerm)
  const eventId = useSelector((state) => state.authentication.eventId)
  const accessCode = useSelector((state) => state.authentication.accessCode)

  const queryParams = {
    eventId: eventId || '',
    page,
    searchTerm: filteredSearchTerm,
    filterBy: filterBy.value,
    accessCode,
  }

  const {
    data,
    isLoading,
    isFetching,
    isError,
    isSuccess,
    error,
    refetch,
  } = useGetAttendeesQuery(queryParams, { skip: !eventId })

  useEffect(() => {
    refetch()
  }, [])

  useEffect(() => {
    // Only trigger a search if the searchTerm is equal or bigger than 3 characters
    // If the searchTerm is empty, then retrieve all results back
    if (searchTerm.length >= 3 || searchTerm.length === 0) {
      setFilteredSearchTerm(searchTerm)
    }
  }, [searchTerm])

  useEffect(() => {
    refetch()
  }, [filteredSearchTerm])

  useAccessCodeError(error)

  const filterOptions = [
    {
      label: 'All',
      value: 'all',
    },
    {
      label: 'Checked in',
      value: 'checked_in',
    },
    {
      label: 'Not Checked in',
      value: 'not_checked_in',
    },
  ]

  const numRecords = isSuccess ? data.pagination.num_records : 0
  const totalRecords = isSuccess ? data.pagination.total_records : 0

  return (
    <PageContainer>
      <section className="attendease-panel">
        <SearchField
          onChange={setSearchTerm}
          placeholder="Search by name or email"
          numRecords={numRecords}
          totalRecords={totalRecords}
          alwaysShowResults={false}
          showResults={false}
          showReloadButton={false}
          reloadList={refetch}
        />

        <Select
          className="attendease-select"
          classNamePrefix="attendease-select"
          options={filterOptions}
          value={filterBy}
          components={{ ValueContainer: renderSelected }}
          onChange={(item) => setFilterBy(item)}
        />

        <div className="attendease-search-actions">
          <div className="attendease-search-result">
            {numRecords
              ? <span>{`Showing ${numRecords} of ${totalRecords}`}</span>
              : <span>0 results found</span>}
          </div>

          <ReloadButton
            onClick={refetch}
          />
        </div>

        {(isLoading || isFetching) && (
          <Spinner />
        )}

        {isError && (
          <div className="text-red-dark">
            There was an error when trying to fetch the data.
          </div>
        )}

        {(isSuccess && !isFetching) && (
          <AttendeeListing
            attendees={camelizeKeys(data.attendees)}
            pagination={camelizeKeys(data.pagination)}
            page={page}
            setPage={setPage}
          />
        )}
      </section>
    </PageContainer>
  )
}

export default AttendeeListingContainer
