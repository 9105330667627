import React from 'react'
import PropTypes from 'prop-types'
import {
  Navigate,
} from 'react-router-dom'

const PrivateRoute = function ({
  children,
  isSignedIn,
}) {
  if (isSignedIn) {
    return children
  }

  return (
    <Navigate to="/login" />
  )
}

PrivateRoute.propTypes = {
  children: PropTypes.node.isRequired,
  isSignedIn: PropTypes.bool.isRequired,
}

export default PrivateRoute
