import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { camelizeKeys } from 'xcase/es5'
import { useGetAttendeesQuery } from '../../../api/apiSlice'
import { useAccessCodeError } from '../../../hooks'
import SearchField from '../../shared/SearchField'
import Paginator from '../../shared/Paginator'
import Spinner from '../../shared/Spinner'
import Attendee from './Attendee'

const TimeSlotAttendees = function ({
  timeSlotId,
  refetchTimeSlot,
}) {
  const [page, setPage] = useState(1)
  const [searchTerm, setSearchTerm] = useState('')
  const [filteredSearchTerm, setFilteredSearchTerm] = useState(searchTerm)
  const eventId = useSelector((state) => state.authentication.eventId)
  const accessCode = useSelector((state) => state.authentication.accessCode)
  const attendeeId = useSelector((state) => state.attendee.id)
  const isCheckedIn = useSelector((state) => state.attendee.isCheckedIn)

  const queryParams = {
    eventId: eventId || '',
    accessCode,
    page,
    searchTerm: filteredSearchTerm,
    timeSlotId,
  }

  const {
    data,
    isLoading,
    isFetching,
    isError,
    isSuccess,
    error,
    refetch,
  } = useGetAttendeesQuery(queryParams, { skip: !eventId })

  const attendees = isSuccess ? camelizeKeys(data.attendees) : []
  const pagination = isSuccess ? camelizeKeys(data.pagination) : {}

  useEffect(() => {
    refetch()
  }, [])

  useEffect(() => {
    if (attendeeId && isCheckedIn) {
      refetch()
    }
  }, [attendeeId, isCheckedIn])

  useEffect(() => {
    // Only trigger a search if the searchTerm is equal or bigger than 3 characters
    // If the searchTerm is empty, then retrieve all results back
    if (searchTerm.length >= 3 || searchTerm.length === 0) {
      setFilteredSearchTerm(searchTerm)
    }
  }, [searchTerm])

  useEffect(() => {
    refetch()
  }, [filteredSearchTerm])

  useAccessCodeError(error)

  const numRecords = isSuccess ? data.pagination.num_records : 0
  const totalRecords = isSuccess ? data.pagination.total_records : 0

  return (
    <div className="attendease-time-slot-attendees attendease-panel">
      <h3 className="text-uppercase">Confirmed Attendees</h3>

      <SearchField
        onChange={setSearchTerm}
        placeholder="Search by name or e-mail"
        alwaysShowResults
        numRecords={numRecords}
        totalRecords={totalRecords}
        reloadList={refetch}
      />

      {(isLoading || isFetching) && (
        <Spinner />
      )}

      {isError && (
        <div className="text-red-dark">
          There was an error when trying to fetch attendee listing.
        </div>
      )}

      {(isSuccess && !isFetching) && (
        <section className="attendease-attendee-listing">
          {attendees.map((attendee) => (
            <Attendee
              attendee={attendee}
              timeSlotId={timeSlotId}
              refetchTimeSlot={refetchTimeSlot}
            />
          ))}

          {(attendees.length === 0 && searchTerm === '') && (
            <span>This time slot does not have confirmed attendees.</span>
          )}
        </section>
      )}

      {(pagination.pageCount > 1) && (
        <div className="mb-2">
          <Paginator page={page} pageCount={pagination.pageCount} onChange={setPage} />
        </div>
      )}
    </div>
  )
}

TimeSlotAttendees.propTypes = {
  timeSlotId: PropTypes.string.isRequired,
  refetchTimeSlot: PropTypes.func.isRequired,
}

export default TimeSlotAttendees
