import React from 'react'
import { useSelector } from 'react-redux'
import Navigation from './Navigation'

const NavigationContainer = function () {
  const modeConfigName = useSelector((state) => state.modeConfig?.data?.mode)

  return (
    <Navigation
      showSessionScan={modeConfigName === 'attendease' || modeConfigName === 'eventup_planner'}
    />
  )
}

export default NavigationContainer
