import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  id: null,
  showProfile: false,
  isCheckedIn: false,
}

export const attendeeSlice = createSlice({
  name: 'attendee',
  initialState,
  reducers: {
    setAttendeeId(state, action) {
      const currentState = state
      currentState.id = action.payload
    },
    setShowProfile(state, action) {
      const currentState = state
      currentState.showProfile = action.payload
    },
    setIsCheckedInSuccess(state, action) {
      const currentState = state
      currentState.isCheckedIn = action.payload
    },
  },
})

export const { setAttendeeId, setShowProfile, setIsCheckedInSuccess } = attendeeSlice.actions

export default attendeeSlice.reducer
