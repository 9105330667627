import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { setIsSignedIn, setAccessCode } from '../api/authentication'

const useAccessCodeError = (error) => {
  const dispatch = useDispatch()

  useEffect(() => {
    // Check if request has auth, otherwise, redirect to the login page
    if (error && error.originalStatus === 401) {
      localStorage.removeItem('accessCode')
      dispatch(setAccessCode(''))
      dispatch(setIsSignedIn(false))
    }
  }, [error])
}

export default useAccessCodeError
