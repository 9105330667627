import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'react-bootstrap'
import { getRoomCapacity } from '../../../utils/tools'
import Icon from '../../shared/Icon'
import BackLink from '../../shared/BackLink'
import CheckinCounter from '../../shared/CheckinCounter'
import TimeSlotAttendees from './TimeSlotAttendees'
import Spinner from '../../shared/Spinner'

import './TimeSlotDetails.scss'

const TimeSlotDetails = function ({
  id,
  scanAttendees,
  refetchTimeSlot,
  isSuccess,
  isFetching,
  timeSlot,
}) {
  const timeSlotData = timeSlot ? timeSlot.instance : {}

  return (
    <section className="attendease-timeslot-details">
      <BackLink
        url="/session-scan"
        label="Back to Sessions"
      />

      {isFetching && (
        <Spinner />
      )}

      {(isSuccess && !isFetching) && (
        <div className="attendease-timeslot-info">
          <div className="attendease-panel">
            <h2 className="attendease-timeslot-session-name">
              {timeSlotData.session_name}
            </h2>

            <div className="attendease-timeslot-date-hour">
              <time>
                <Icon
                  beforeText
                  category="light"
                  type="calendar-alt"
                />
                {`${timeSlotData.date} ${timeSlotData.start_time} - ${timeSlotData.end_time}`}
              </time>
            </div>
            <p className="attendease-timeslot-location">
              <Icon
                beforeText
                category="light"
                type="hotel"
              />
              <span>
                {`${timeSlotData.venue_name}, `}
                {`${timeSlotData.room_name} (Capacity: ${getRoomCapacity(timeSlotData.room_capacity)})`}
              </span>
            </p>

            <CheckinCounter
              current={timeSlotData.checked_in_attendees_count}
              total={timeSlotData.scheduled_attendees_count}
            />

            <Button
              className="attendease-scan-button"
              variant="primary"
              onClick={scanAttendees}
            >
              <Icon
                category="regular"
                type="qrcode"
              />
              Scan Attendees
            </Button>
          </div>
        </div>
      )}

      <TimeSlotAttendees
        timeSlotId={id}
        refetchTimeSlot={refetchTimeSlot}
      />
    </section>
  )
}

TimeSlotDetails.defaultProps = {
  timeSlot: {},
}

TimeSlotDetails.propTypes = {
  id: PropTypes.string.isRequired,
  scanAttendees: PropTypes.func.isRequired,
  refetchTimeSlot: PropTypes.func.isRequired,
  isSuccess: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  timeSlot: PropTypes.shape({
    instance: PropTypes.shape({}),
  }),
}

export default TimeSlotDetails
