import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Container } from 'react-bootstrap'
import Header from '../../Header'
import Navigation from '../../Navigation'
// import CheckinCounter from '../CheckinCounter'

import './PageContainer.scss'

const PageContainer = function ({
  pageClassName,
  showNav,
  showHeader,
  children,
  hasPadding,
}) {
  return (
    <section
      className={classnames('attendease-page', pageClassName)}
    >
      {showHeader && (
        <Header />
      )}

      <div
        className="attendease-page-container"
      >
        {/* <CheckinCounter
          current={50}
          total={100}
          className="attendease-panel"
        /> */}

        {showNav && (
          <Navigation />
        )}
        <Container className={classnames('attendease-container', { 'no-padding': !hasPadding })}>
          {children}
        </Container>
      </div>
    </section>
  )
}

PageContainer.defaultProps = {
  pageClassName: '',
  children: null,
  showHeader: true,
  showNav: true,
  hasPadding: true,
}

PageContainer.propTypes = {
  pageClassName: PropTypes.string,
  showNav: PropTypes.bool,
  children: PropTypes.node,
  showHeader: PropTypes.bool,
  hasPadding: PropTypes.bool,
}

export default PageContainer
