import React from 'react'
import PropTypes from 'prop-types'
import BackLink from '../../shared/BackLink'
import CheckInButton from '../../shared/CheckInButton'
import UserProfile from '../../shared/UserProfile'
import AttendeeInfo from '../../AttendeeInfo'

import './AttendeeDetails.scss'

const AttendeeDetails = function ({
  attendee = {},
  checkInEvent,
  checkinDateTime,
  isCheckinLoading,
  isCheckInSuccess,
  showSchedule,
}) {
  const hasProfile = attendee.profile
  const hasRegisteringAttendee = !!attendee.registeringAttendee
  const primaryAttendeeName = hasRegisteringAttendee
    ? `${attendee.registeringAttendee.firstName} ${attendee.registeringAttendee.lastName}`
    : ''
  const primaryAttendeeEmail = hasRegisteringAttendee
    ? attendee.registeringAttendee.email
    : ''

  return (
    <section className="attendease-attendee-details">
      <BackLink
        url="/attendees"
        label="Back to Attendees"
      />

      <div className="attendease-panel attendease-attendee-summary">
        <UserProfile
          firstName={attendee.firstName}
          lastName={attendee.lastName}
          avatarUrl={hasProfile ? attendee.profile.profileUrl : ''}
          passName={attendee.passName}
          passGroupName={attendee.passGroupName}
          avatarSize={75}
          avatarPosition="left"
        />

        <CheckInButton
          label="Event Check-in"
          className={checkinDateTime ? 'checkin-date-time' : 'btn btn-success'}
          checkInDate={checkinDateTime}
          checkAttendeeIn={!checkinDateTime ? () => checkInEvent() : null}
          isCheckInSuccess={isCheckInSuccess}
          isLoading={isCheckinLoading}
        />
      </div>

      <div className="attendease-panel">
        <AttendeeInfo
          id={attendee.id}
          email={attendee.email}
          company={hasProfile ? attendee.profile.company : ''}
          jobTitle={hasProfile ? attendee.profile.title : ''}
          facebook={hasProfile ? attendee.profile.facebook : ''}
          instagram={hasProfile ? attendee.profile.instagram : ''}
          linkedin={hasProfile ? attendee.profile.linkedin : ''}
          twitter={hasProfile ? attendee.profile.twitter : ''}
          notes={attendee.notes}
          primaryAttendeeName={!attendee.selfRegistered ? primaryAttendeeName : ''}
          primaryAttendeeEmail={!attendee.selfRegistered ? primaryAttendeeEmail : ''}
          showSchedule={showSchedule}
        />
      </div>
    </section>
  )
}

AttendeeDetails.defaultProps = {
  checkinDateTime: '',
}

AttendeeDetails.propTypes = {
  checkinDateTime: PropTypes.string,
  attendee: PropTypes.shape({}).isRequired,
  checkInEvent: PropTypes.func.isRequired,
  isCheckinLoading: PropTypes.bool.isRequired,
  isCheckInSuccess: PropTypes.bool.isRequired,
  showSchedule: PropTypes.bool.isRequired,
}

export default AttendeeDetails
