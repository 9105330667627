import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { FormControl } from 'react-bootstrap'
import Icon from '../Icon'
import { useDebounce } from '../../../hooks'
import ReloadButton from '../ReloadButton/ReloadButton'

import './SearchField.scss'
/**
 * Renders a search field component along with total results. <br/>
 * If the field has value, a "clear" button will show on the left of the text. <br/>
 * Total results visibility is controlled by `showResults` and `alwaysShowResults` props.
 */
const SearchField = function ({
  onChange,
  placeholder,
  alwaysShowResults = true,
  numRecords = 0,
  totalRecords = 0,
  className,
  showResults,
  reloadList,
  showReloadButton,
}) {
  const [value, setValue] = useState('')
  const searchTerm = useDebounce(value, 500).trim()
  const canShowResults = showResults && searchTerm

  useEffect(() => {
    onChange(searchTerm)
  }, [searchTerm])

  return (
    <div className={`attendease-search-field ${className}`}>
      <div className="attendease-search-field-container">
        <FormControl
          id="attendease-search-field"
          placeholder={placeholder}
          type="search"
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />

        {searchTerm
          ? (
            <Icon
              category="solid"
              className="attendease-search-clear"
              type="times"
              onClick={() => setValue('')}
            />
          )
          : (
            <Icon
              category="regular"
              type="search"
            />
          )}
      </div>

      <div className="attendease-search-actions">
        {(alwaysShowResults || canShowResults) && (
          <span className="attendease-search-result">
            {numRecords
              ? <span>{`Showing ${numRecords} of ${totalRecords}`}</span>
              : <span>0 results found</span>}
          </span>
        )}

        {showReloadButton && (
          <ReloadButton
            onClick={reloadList}
          />
        )}
      </div>
    </div>
  )
}

SearchField.defaultProps = {
  totalRecords: 0,
  numRecords: 0,
  className: '',
  showResults: true,
  placeholder: 'Search...',
  reloadList: null,
  showReloadButton: true,
}

SearchField.propTypes = {
  /**
    Number of records to display.
  */
  numRecords: PropTypes.number,
  /**
    Number of total records to display.
  */
  totalRecords: PropTypes.number,
  /**
    Field text placeholder. It shows when the field has no value.
  */
  placeholder: PropTypes.string,
  /**
    A callback fired when the user is typing (considering a debounce value)
  */
  onChange: PropTypes.func.isRequired,
  /**
    Results will only show if this props is true and field has a value.
  */
  showResults: PropTypes.bool,
  /**
    Results will always show no matter if field has value or not.
  */
  alwaysShowResults: PropTypes.bool.isRequired,
  /**
    It renders a reload button that refetches a list on user click.
  */
  reloadList: PropTypes.func,
  /**
    Add CSS classes to the `.attendease-search-field` element.
  */
  className: PropTypes.string,
  /**
    Show a reload icon at the bottom-right of the component to fetch the list again.
  */
  showReloadButton: PropTypes.bool,
}

export default SearchField
