import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { camelizeKeys } from 'xcase/es5'
import { useGetSessionsQuery } from '../../../api/apiSlice'
import { useAccessCodeError } from '../../../hooks'
import Spinner from '../../shared/Spinner'
import SearchField from '../../shared/SearchField'
import PageContainer from '../../shared/PageContainer'
import TimeSlotListing from './TimeSlotListing'

const TimeSlotsContainer = function () {
  const [page, setPage] = useState(1)
  const [searchTerm, setSearchTerm] = useState('')
  const [filteredSearchTerm, setFilteredSearchTerm] = useState(searchTerm)
  const eventId = useSelector((state) => state.authentication.eventId)
  const accessCode = useSelector((state) => state.authentication.accessCode)

  const queryParams = {
    eventId: eventId || '',
    page,
    searchTerm: filteredSearchTerm,
    accessCode,
  }

  const {
    data,
    isLoading,
    isFetching,
    isError,
    isSuccess,
    error,
    refetch,
  } = useGetSessionsQuery(queryParams, { skip: !eventId })

  useEffect(() => {
    // Only trigger a search if the searchTerm is equal or bigger than 3 characters
    // If the searchTerm is empty, then retrieve all results back
    if (searchTerm.length >= 3 || searchTerm.length === 0) {
      setFilteredSearchTerm(searchTerm)
    }
  }, [searchTerm])

  useAccessCodeError(error)

  return (
    <PageContainer>
      <section className="attendease-panel">
        <SearchField
          onChange={setSearchTerm}
          placeholder="Search by name, venue or room..."
          numRecords={isSuccess ? data.pagination.num_records : 0}
          totalRecords={isSuccess ? data.pagination.total_records : 0}
          alwaysShowResults
          reloadList={refetch}
        />

        {(isLoading || isFetching) && (
          <Spinner />
        )}

        {isError && (
          <div className="text-red-dark">
            There was an error when trying to fetch the data.
          </div>
        )}

        {(isSuccess && !isFetching) && (
          <TimeSlotListing
            timeSlots={camelizeKeys(data.instances)}
            pagination={camelizeKeys(data.pagination)}
            page={page}
            setPage={setPage}
          />
        )}
      </section>
    </PageContainer>
  )
}

export default TimeSlotsContainer
