import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useGetTimeSlotQuery } from '../../../api/apiSlice'
import PageContainer from '../../shared/PageContainer'
import ScanSessionQrCode from '../../ScanSessionQrCode'
import TimeSlotDetails from './TimeSlotDetails'

const TimeSlotDetailsContainer = function ({
  timeSlotId,
}) {
  const [showScanModal, setShowScanModal] = useState(false)
  const eventId = useSelector((state) => state.authentication.eventId)
  const accessCode = useSelector((state) => state.authentication.accessCode)
  const attendeeId = useSelector((state) => state.attendee.id)
  const isCheckedIn = useSelector((state) => state.attendee.isCheckedIn)

  const queryParams = {
    eventId: eventId || '',
    accessCode,
    timeSlotId,
  }

  const {
    data,
    // isLoading,
    isFetching,
    isError,
    isSuccess,
    refetch,
  } = useGetTimeSlotQuery(queryParams, { skip: !eventId })

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (attendeeId && isCheckedIn) {
      refetch()
    }
  }, [attendeeId, isCheckedIn])

  return (
    <PageContainer>
      <section className="attendease-time-slot-page">
        {isError && (
          <div className="text-red-dark">
            There was an error when trying to fetch session details.
          </div>
        )}

        <TimeSlotDetails
          id={timeSlotId}
          timeSlot={data}
          scanAttendees={() => setShowScanModal(true)}
          refetchTimeSlot={refetch}
          isSuccess={isSuccess}
          isFetching={isFetching}
        />

        {isSuccess && showScanModal && (
          <ScanSessionQrCode
            instanceId={timeSlotId}
            sessionName={data.instance.session_name}
            date={data.instance.date}
            startTime={data.instance.start_time}
            endTime={data.instance.end_time}
            show={showScanModal}
            close={() => setShowScanModal(false)}
          />
        )}
      </section>
    </PageContainer>
  )
}

TimeSlotDetailsContainer.propTypes = {
  timeSlotId: PropTypes.string.isRequired,
}

export default TimeSlotDetailsContainer
