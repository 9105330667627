import React from 'react'
import {
  useCheckInEventMutation,
} from '../../../api/apiSlice'
import PageContainer from '../../shared/PageContainer'
import QrCodeModule from '../../shared/QrCodeModule'

const EventQrCode = function () {
  const [checkInEvent, {
    isSuccess: isCheckInSuccess,
    isError: isCheckInError,
    error: checkInError,
  }] = useCheckInEventMutation()

  return (
    <section className="attendease-event-qr-code-page">
      <PageContainer>
        <QrCodeModule
          checkInAttendee={checkInEvent}
          isCheckInSuccess={isCheckInSuccess}
          isCheckInError={isCheckInError}
          checkInError={checkInError || {}}
        />
      </PageContainer>
    </section>
  )
}

export default EventQrCode
